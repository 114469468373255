import React from 'react';
import { useModalContext } from '../../modal-container/modal-container.context';
import classNames from 'classnames';
import { StyledModal, StyledModalWrapper } from './modal-renderer.styles';
import {
  SwitchTransition,
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { StyledModalSwitch } from './modal-switch.styles';

export const ModalRenderer: React.FC = () => {
  const modalContext = useModalContext();

  const onEnterHandler = () => {
    modalContext.onAnimationStateChange('enter');
  };

  const onEnteringHandler = () => {
    modalContext.onAnimationStateChange('entering');
    window && window.dispatchEvent(new CustomEvent('scroll'));
  };

  const onEnteredHandler = () => {
    modalContext.onAnimationStateChange('entered');
  };

  const onExitHandler = () => {
    modalContext.onAnimationStateChange('exit');
  };

  const onExitingHandler = () => {
    modalContext.onAnimationStateChange('exiting');
  };

  const onExitedHandler = () => {
    modalContext.onAnimationStateChange('exited');
  };

  return (
    <TransitionGroup>
      {modalContext.modal && (
        <CSSTransition
          timeout={350}
          onEnter={onEnterHandler}
          onEntering={onEnteringHandler}
          onEntered={onEnteredHandler}
          onExit={onExitHandler}
          onExiting={onExitingHandler}
          onExited={onExitedHandler}
        >
          <StyledModalWrapper>
            <SwitchTransition>
              <CSSTransition timeout={350} key={modalContext.modalId}>
                <StyledModalSwitch>
                  <StyledModal
                    className={classNames(
                      'modal',
                      `modal--${modalContext.modalId}`
                    )}
                  >
                    {modalContext.modal && <modalContext.modal />}
                  </StyledModal>
                </StyledModalSwitch>
              </CSSTransition>
            </SwitchTransition>
          </StyledModalWrapper>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
