import React from 'react';
import { NavMenu } from '../navigation/nav-menu';
import { MenuFragmentFragment } from '../../../types/gatsby-types';
import { selectSignOff } from './footer.helpers';
import NmblLogomark from '../../svg/nmblLogomark.svg';
import { Link } from '../navigation/link';
import { StyledFooter } from './footer.styles';
import { StyledWrapper } from '../parts/wrapper.styles';

export interface IFooterProps {
  menu: MenuFragmentFragment;
}

export const Footer: React.FC<IFooterProps> = props => {
  const [signoff, setSignoff] = React.useState<string | undefined>();
  React.useEffect(() => setSignoff(selectSignOff()), []);

  return (
    <StyledWrapper as={StyledFooter} className="footer">
      <nav className="footer__navigation">
        <NavMenu menu={props.menu} className="main-navigation" />
      </nav>
      <Link className="footer__logo" to="/">
        <NmblLogomark />
      </Link>
      <section className="footer__signoff">{signoff}</section>
    </StyledWrapper>
  );
};
