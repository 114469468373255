import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import {
  smallParagraphStyles,
  LabelTextStyles,
  InputLabelFocusStyles,
} from '../../abstracts/typography.styles';
import { animation } from '../../abstracts/animation.styles';
import { media } from '../../abstracts/media-settings.styles';

export const StyledTextInput = styled.div`
  color: ${props => props.theme.secondary};
  font-family: ${font.primary};
  position: relative;

  input {
    ${LabelTextStyles}
    border: 1px solid ${props => props.theme.secondary};
    box-shadow: none;
    border-radius: 5px;
    padding-top: 19px;
    padding-right: 14px;
    padding-bottom: 19px;
    padding-left: 14px;
    margin-bottom: 0;
    transition: border ${animation.hover}, color ${animation.hover}, padding ${
  animation.hover
};
    outline: 0;
    position: relative;

    & + label {
      ${InputLabelFocusStyles}
      opacity: 0;
      position: absolute;
      top: 8px;
      left: 14px;
      transition: opacity ${animation.hover};
      pointer-events: none;

      @media ${media.tabletLandscape} {
        top: 0;
      }
    }

    &::placeholder {
      color: ${props => props.theme.secondary};
      transition: color ${animation.hover};
    }

    &:focus + label {
      opacity: 1;
    }

    &:hover {
      color: ${props => props.theme.secondaryOpaque};
      border: 1px solid ${props => props.theme.secondaryOpaque};

      &::placeholder {
        color: ${props => props.theme.secondaryOpaque};
      }
    }

    &:focus {
      border: 2px solid ${props => props.theme.secondary};
      padding-top: 18px;
      padding-bottom: 18px;

      &::placeholder {
        color: ${props => props.theme.primary};
      }
    }
  }

  &.field--error {
    input {
      color: ${props => props.theme.error};
      border-color: ${props => props.theme.error};

      &::placeholder {
        color: ${props => props.theme.error};
        border-color: ${props => props.theme.error};
      }

      &:focus {
        &::placeholder {
          color: ${props => props.theme.primary};
          border-color: ${props => props.theme.primary};
        }
      }
    }
  }

  textarea {
    ${smallParagraphStyles}
    border: 1px solid ${props => props.theme.secondary};
    outline: 0;
    box-shadow: none;
    border-radius: 5px;
    padding-top: 9px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    margin-bottom: 0;
    transition: border ${animation.hover};

    @media ${media.tabletLandscape} {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid ${props => props.theme.secondaryOpaque};
    }

    &:focus {
      border: 2px solid ${props => props.theme.secondary};
    }
  }

  &.field--has-value {
    input {
      color: ${props => props.theme.secondary};

      &:hover {
        color: ${props => props.theme.secondary};
      }
    }
  }

  &.field--email,
  &.field--tel,
  &.field--text {
    display: flex;
    flex-direction: column;
  }

  &.field--textarea {
    display: flex;
    flex-direction: column-reverse;

    textarea {
      min-height: 150px;
    }

    &.field--error {
      textarea {
        border-color: ${props => props.theme.error};
      }
    }
  }
`;
