import styled from 'styled-components';
import {
  smallParagraphStyles,
  paragraphStyles,
  bragParagraphStyles,
} from '../../abstracts/typography.styles';
import { font } from '../../abstracts/variables/fonts.styles';
import {
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  unorderedList,
  orderedList,
  LinkStyles,
} from '../../abstracts/typography.styles';

export const StyledCmsContent = styled.div`
  color: ${props => props.theme.secondary};
  font-family: ${font.primary};

  h1 {
    ${h1Styles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
    margin-bottom: 0.6em;
  }

  h4 {
    ${h4Styles}
  }

  h5 {
    ${h5Styles}
  }

  p {
    ${paragraphStyles}
  }

  strong {
    font-family: ${font.primaryBold};
  }

  em,
  i {
    font-family: ${font.primaryItalic};
  }

  ul {
    ${unorderedList}
  }

  ol {
    ${orderedList}
  }

  a {
    ${LinkStyles}
  }

  .paragraph--small {
    ${smallParagraphStyles}
  }

  .paragraph--brag {
    ${bragParagraphStyles}
  }
`;
