import React from 'react';
import {
  WidgetFragment_Nmbl_CareersWidget_Fragment,
  CareersQuery,
} from '../../../types/gatsby-types';
import { Career } from '../parts/career';
import { useStaticQuery, graphql } from 'gatsby';
import { filterNotEmpty } from '../../helpers/arrayHelpers';
import { StyledWrapper } from '../../components/parts/wrapper.styles';
import { StyledCareersWidget } from './careers-widget.styles';

export const useCareersQuery = () => {
  const careersQuery = useStaticQuery<CareersQuery>(graphql`
    query Careers {
      nmbl {
        career(orderBy: { publishedUtc: DESC }) {
          ...CareerFragment
        }
      }
    }
  `);

  return careersQuery;
};

export const CareersWidgetFragment = graphql`
  fragment CareersWidgetFragment on Nmbl_CareersWidget {
    theme {
      ...ThemeFragment
    }
  }
`;

export const CareersWidget: React.FC<WidgetFragment_Nmbl_CareersWidget_Fragment> = props => {
  const query = useCareersQuery();

  return (
    <StyledWrapper as={StyledCareersWidget} className="careers-widget">
      {query.nmbl.career?.filter(filterNotEmpty).map((career, index) => (
        <React.Fragment key={index}>
          <Career {...career} />
          <div className="careers-widget__spacer" />
        </React.Fragment>
      ))}
    </StyledWrapper>
  );
};
