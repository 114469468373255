import React from 'react';
import { TextInput, TextInputProps } from './text-input';
import { validatePhone } from '../../helpers/validation-helpers';

export type PhoneInputProps = Omit<TextInputProps, 'type'>;

export const PhoneInput: React.FC<PhoneInputProps> = props => {
  const validate = React.useCallback(validatePhone(props.required), [
    props.required,
  ]);
  return (
    <TextInput validate={props.validate ?? validate} type="tel" {...props} />
  );
};
