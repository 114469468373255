import { useRef, useEffect, useCallback, useState } from 'react';

export const useLastRef = <TValue>(value: TValue) => {
  const ref = useRef<TValue | undefined>();
  useEffect(() => {
    ref.current = value;
  });

  return ref;
};

export const useLast = <TValue>(value: TValue) => {
  return useLastRef(value).current;
};

export const useLastKnown = <TValue>(value: TValue) => {
  const ref = useRef<TValue | undefined>(undefined);

  if (typeof value !== 'undefined') {
    ref.current = value;
  }

  return ref.current;
};

export const useEventCallback = <Args extends any[]>(
  fn: (...args: Args) => void,
  dependencies: any[]
) => {
  const ref = useRef<typeof fn>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useEffect(() => {
    ref.current = fn;
  }, [fn, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  return useCallback(
    (...args: Args) => {
      ref.current(...args);
    },
    [ref]
  );
};

export const useLocalStorage = <TValue extends any[]>(
  key: string,
  initialValue: TValue
): [TValue, (value: TValue) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<TValue>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: TValue) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {}
  };

  return [storedValue, setValue];
};
