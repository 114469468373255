import React from 'react';
import { NavMenu } from '../navigation/nav-menu';
import { MenuFragmentFragment } from '../../../types/gatsby-types';
import { Link } from '../navigation/link';
import { ScreenReader } from './screen-reader';
import { useModalContext } from '../modal-container/modal-container.context';
import NmblLogotype from '../../svg/nmblLogotype.svg';
import { StyledHeader } from './header.styles';
import MenuSvg from '../../svg/menuSvg.svg';
import { useWindowContext } from '../in-view/window-provider.context';
import classNames from 'classnames';
import { useLastKnown } from '../../helpers/hooks';
import { useSiteMetaContext } from '../site-meta';
import { ThemeName } from '../../helpers/theme-helpers';

export interface IHeaderProps {
  /**
   * Header Nav Menu from GraphQL
   */
  menu: MenuFragmentFragment;
  /**
   * Height at which smart header kicks in when scrolling up
   */
  smartHeaderThreshold?: number;
  /**
   * Override page header theme when the smart header is active
   */
  headerTheme?: ThemeName;
  smartHeaderTheme?: ThemeName;
}

export const Header: React.FC<IHeaderProps> = props => {
  const {
    updatePageHeaderTheme,
    modalRef,
    onClose,
    modalId,
    modal,
    savedScrollPosition,
    animationState,
  } = useModalContext();
  const { scrollState } = useWindowContext();
  const [isScrolledUp, setIsScrolledUp] = React.useState(true);
  const [isSmartHeaderActive, setIsSmartHeaderActive] = React.useState(false);
  const [wasSmartHeaderActive, setWasSmartHeaderActive] = React.useState(
    isSmartHeaderActive
  );
  const [atTop, setAtTop] = React.useState(true);
  const lastModalIdRef = useLastKnown(modalId);
  const smartHeaderThreshold = props.smartHeaderThreshold ?? 700;

  const handleClose = React.useCallback<React.MouseEventHandler>(
    event => {
      event.preventDefault();
      onClose();
    },
    [onClose]
  );

  const updateSmartHeaderActive = React.useCallback(
    (currentPosition: number) => {
      if (currentPosition > smartHeaderThreshold) {
        setIsSmartHeaderActive(true);

        if (props.smartHeaderTheme) {
          updatePageHeaderTheme(props.smartHeaderTheme);
        }
      } else if (currentPosition === 0 && !modalRef.current) {
        if (isSmartHeaderActive) {
          setWasSmartHeaderActive(true);

          setTimeout(() => setWasSmartHeaderActive(false), 500);
        }

        setIsSmartHeaderActive(false);

        if (props.headerTheme) {
          updatePageHeaderTheme(props.headerTheme);
        }
      }
    },
    [
      modalRef,
      isSmartHeaderActive,
      props.headerTheme,
      props.smartHeaderTheme,
      updatePageHeaderTheme,
      smartHeaderThreshold,
    ]
  );

  const updateScrolledUp = React.useCallback(
    (currentPosition: number, prevPosition: number) => {
      if (currentPosition > prevPosition) {
        setIsScrolledUp(false);
      } else {
        setIsScrolledUp(true);
      }
    },
    []
  );

  const checkAtTop = React.useCallback((position: number) => {
    position < 1 ? setAtTop(true) : setAtTop(false);
  }, []);

  React.useEffect(() => {
    if (
      animationState === 'exit' &&
      lastModalIdRef !== 'nav' &&
      savedScrollPosition !== 0
    ) {
      setIsScrolledUp(false);
    }

    if (animationState === 'exiting') {
      checkAtTop(savedScrollPosition);
      updateSmartHeaderActive(savedScrollPosition);
    }
  }, [
    animationState,
    savedScrollPosition,
    modalId,
    lastModalIdRef,
    scrollState,
    checkAtTop,
    updateSmartHeaderActive,
  ]);

  React.useEffect(() => {
    if (scrollState && scrollState.prevPosition?.scrollY) {
      updateSmartHeaderActive(scrollState.currentPosition.scrollY);
      updateScrolledUp(
        scrollState.currentPosition.scrollY,
        scrollState.prevPosition.scrollY
      );
      checkAtTop(scrollState.currentPosition.scrollY);
    }
  }, [scrollState, updateSmartHeaderActive, checkAtTop, updateScrolledUp]);

  return (
    <StyledHeader
      className={classNames(
        'header',
        isScrolledUp && 'header--scrolled-up',
        isSmartHeaderActive && 'header--smart-header-active',
        wasSmartHeaderActive && 'header--smart-header-was-active',
        atTop &&
          (animationState === 'exited' || savedScrollPosition === 0) &&
          'header--at-top',
        (atTop || (isScrolledUp && isSmartHeaderActive)) && 'header--visible',
        `header--modal-animation-${animationState}`
      )}
    >
      <nav className="header__navigation">
        <Link className="header__logo" to="/">
          <NmblLogotype />
        </Link>
        <Link className="header__menu" to="#nav" onClick={modal && handleClose}>
          <MenuSvg />
        </Link>
        <ScreenReader>
          <NavMenu menu={props.menu} className="header" />
        </ScreenReader>
      </nav>
    </StyledHeader>
  );
};
