import React from 'react';
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from 'react-transition-group';
import { useLocation } from '@reach/router';
import { StyledPageTransition } from './page-transition.styles';
import { useEventCallback } from '../../helpers/hooks';
import { usePageTransitionContext } from './page-transition-provider';
import { pageViewEvent } from '../../helpers/analyticsHelpers';

export const PageTransition: React.FC = props => {
  const $html = React.useRef<HTMLElement | null>(null);
  const location = useLocation();
  const { onStart, onEnd } = usePageTransitionContext();

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      $html.current = document.documentElement;
    }
  }, []);

  const onExit = useEventCallback(() => {
    onStart();

    if ($html.current) {
      $html.current.classList.add('page-transitioning');
    }
  }, []);

  const onExited = useEventCallback(() => {
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  }, []);

  const onEntered = useEventCallback(() => {
    onEnd();

    if ($html.current) {
      $html.current.classList.remove('page-transitioning');
    }

    pageViewEvent(location);
  }, []);

  return (
    <TransitionGroup>
      <SwitchTransition>
        <CSSTransition
          timeout={350}
          key={location.pathname}
          onExit={onExit}
          onExited={onExited}
          onEntered={onEntered}
        >
          <StyledPageTransition>{props.children}</StyledPageTransition>
        </CSSTransition>
      </SwitchTransition>
    </TransitionGroup>
  );
};
