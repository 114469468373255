import styled from 'styled-components';
import { specialtyStyles } from '../../abstracts/typography.styles';
import { animation } from '../../abstracts/animation.styles';

export const StyledDrawer = styled.section`
  width: 100%;

  &.drawer {
    &--open {
      .drawer__content {
        max-height: 1000px;
        transition: max-height ${animation.hover};
      }

      .drawer__icon {
        .plus--vertical {
          transform: rotate(45deg);
        }

        .plus--horizontal {
          transform: rotate(-135deg);
        }
      }
    }

    &--closed {
      .drawer__content {
        max-height: 0;
        transition: max-height ${animation.hover};
      }

      .drawer__icon {
        .plus--vertical {
          transform: rotate(0);
        }

        .plus--horizontal {
          transform: rotate(0);
        }
      }
    }
  }

  .drawer__content {
    padding-bottom: 1px;
    overflow: hidden;
  }

  .drawer__toggle {
    ${specialtyStyles}
    background-color: transparent;
    color: ${props => props.theme.secondary};
    border-top: 1px solid ${props => props.theme.secondaryWhisper};
    margin-top: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .drawer__icon {
    display: block;
    float: right;
    width: 10px;
    height: 10px;
    fill: ${props => props.theme.secondary};
    opacity: 0.3;

    .plus--vertical {
      transition: transform 20ms ease-in;
      transform-origin: 50% 50%;
    }

    .plus--horizontal {
      transition: transform 20ms ease-in;
      transform-origin: 50% 50%;
    }
  }

  button {
    display: block;
    width: 100%;
    text-align: left;
  }
`;
