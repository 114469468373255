import * as React from 'react';
import { FormWrapperContext, FormStatus } from './form-wrapper.context';
import { CSSTransition } from 'react-transition-group';
import { StyledFormWrapper, StyledFormContent } from './form-wrapper.styles';
import { StyledParagraph } from '../../abstracts/typography.styles';

export const FormSuccess: React.FC = props => {
  const formContext = React.useContext(FormWrapperContext);

  return formContext?.status === FormStatus.Success ? (
    <CSSTransition timeout={350} key={FormStatus.Success}>
      <StyledFormWrapper className="form-success">
        <StyledFormContent as={StyledParagraph}>
          {props.children}
        </StyledFormContent>
      </StyledFormWrapper>
    </CSSTransition>
  ) : null;
};
