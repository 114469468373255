import * as React from 'react';
import { debounce, throttle } from 'lodash';
import { scrollTop } from '../../helpers/positionHelpers';
import { useEventCallback } from '../../helpers/hooks';
import { WindowContext, IWindowContext } from './window-provider.context';

const RESIZE_INTERVAL = 250;
const SCROLL_INTERVAL = 25;

export interface ScrollPosition {
  scrollY: number;
}

export interface ScrollState {
  currentPosition: ScrollPosition;
  prevPosition?: ScrollPosition;
}

export interface Dimensions {
  height: number;
  width: number;
}

const hasWindow = typeof window === 'object';

const useWindowSize = () => {
  const selectWindowSize = () =>
    hasWindow
      ? {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      : undefined;

  const [windowSize, setWindowSize] = React.useState(selectWindowSize);

  const updateWindowSize = debounce(() => {
    setWindowSize(selectWindowSize);
  }, RESIZE_INTERVAL);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', updateWindowSize);

    return () => window.removeEventListener('resize', updateWindowSize);
  }, [updateWindowSize]);

  return windowSize;
};

const selectScrollState = (
  prevPosition?: ScrollPosition
): ScrollState | undefined =>
  hasWindow
    ? {
        currentPosition: {
          scrollY: scrollTop(),
        },
        prevPosition,
      }
    : undefined;

export type ScrollCallback = (scrollY?: number, lastScrollY?: number) => void;

export const useScrollState = () => {
  const [scrollState, setScrollState] = React.useState(selectScrollState);
  const [scrollCallbacks, setScrollCallbacks] = React.useState<
    ScrollCallback[]
  >([]);

  const registerScrollCallback = React.useCallback(
    (scrollCallback: ScrollCallback) => {
      setScrollCallbacks([...scrollCallbacks, scrollCallback]);
    },
    [scrollCallbacks]
  );

  const unregisterScrollCallback = React.useCallback(
    (scrollCallback: ScrollCallback) => {
      setScrollCallbacks(
        scrollCallbacks.filter(callback => callback !== scrollCallback)
      );
    },
    [scrollCallbacks]
  );

  if (typeof window !== 'undefined') {
    const throttledSetScrollState = throttle(setScrollState, SCROLL_INTERVAL);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleScrollEvent = useEventCallback(() => {
      const newScrollState = selectScrollState(
        scrollState && scrollState.currentPosition
      );

      scrollCallbacks.map(scrollCallback =>
        scrollCallback(
          newScrollState && newScrollState.currentPosition.scrollY,
          newScrollState &&
            newScrollState.prevPosition &&
            newScrollState.prevPosition.scrollY
        )
      );

      throttledSetScrollState(newScrollState);
    }, [scrollState && scrollState.currentPosition, scrollCallbacks]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      window.addEventListener('scroll', handleScrollEvent);

      return () => {
        window.removeEventListener('scroll', handleScrollEvent);
      };
    }, [handleScrollEvent]);
  }
  return {
    scrollState: scrollState,
    registerScrollCallback,
    unregisterScrollCallback,
  };
};

export const useTransitioningElementsState = () => {
  const [
    transitioningElementsState,
    setTransitioningElementsState,
  ] = React.useState(0);

  const transitioningElementsCallback = React.useCallback(
    (transitioningElements: number) => {
      transitioningElements !== transitioningElementsState &&
        setTransitioningElementsState(transitioningElements);
    },
    [transitioningElementsState]
  );

  return {
    transitioningElementsState: transitioningElementsState,
    transitioningElementsCallback,
  };
};

export const WindowContextProvider: React.FC = ({ children }) => {
  const windowSize = useWindowSize();
  const scrollState = useScrollState();
  const transitioningElementsState = useTransitioningElementsState();
  const windowContext: IWindowContext = {
    windowSize: windowSize,
    ...scrollState,
    ...transitioningElementsState,
  };

  return (
    <WindowContext.Provider value={windowContext}>
      {children}
    </WindowContext.Provider>
  );
};
