export interface Range {
  min: number;
  max: number;
}

export const intArrayToRangeValueConverter = (value: number[]): Range => ({
  min: value[0],
  max: value[1],
});

export const rangeToIntArrayValueConverter = (value: Range): number[] => [
  value.min,
  value.max,
];
