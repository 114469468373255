import * as React from 'react';

export enum FormStatus {
  Filling = 'filling',
  Success = 'success',
  Error = 'error',
}

export interface IFormWrapperContext {
  status: FormStatus;
  setStatus: (status: FormStatus) => void;
  onSuccess: (shouldClose?: boolean) => void;
  beforeSubmit: () => void;
  onError: () => void;
}

export const FormWrapperContext = React.createContext<IFormWrapperContext | null>(
  null
);
