import styled from 'styled-components';

export const StyledErrorMessage = styled.div`
  color: ${props => props.theme.error};

  .field--textarea & {
    position: absolute;
    bottom: -28px;
  }
`;
