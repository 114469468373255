import * as React from 'react';
import { useFormikContext } from 'formik';
import { StyledSubmit } from './submit.styles';

export interface ISubmitButtonProps {
  disabled?: boolean;
  text?: string;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = props => {
  const formik = useFormikContext();

  return (
    <StyledSubmit
      type="submit"
      disabled={props.disabled ?? formik.isSubmitting}
    >
      {props.text ?? 'Send'}
    </StyledSubmit>
  );
};
