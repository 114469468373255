import React from 'react';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { ValidationCallback } from '../../helpers/formHelpers';
import { Range } from '../../helpers/input-helpers';
import {
  validateRange,
  NumberFormatCallback,
} from '../../helpers/validation-helpers';
import { StyledRangeInput } from './range-input.styles';
import { StyledLabel } from '../../abstracts/typography.styles';
import { Range as RangeComponent } from 'rc-slider';
import { SliderHandle } from './parts/slider-handle';
import 'rc-slider/assets/index.css';
import { StyledErrorMessage } from './error-message-wrapper.styles';

export interface IRangeInputProps<ParsedValue> {
  name: string;
  label: string;
  min: number;
  max: number;
  validate?: ValidationCallback<ParsedValue>;
  onParse: (value: number[]) => ParsedValue;
  onFormat: (value: ParsedValue) => number[];
  formatLabel?: NumberFormatCallback;
}

export const RangeInput = <ParsedValue extends any = Range>(
  props: React.PropsWithChildren<IRangeInputProps<ParsedValue>>
) => {
  const validate = React.useCallback(
    validateRange(props.min, props.max, true, props.formatLabel),
    [props.min, props.max, props.formatLabel]
  );
  const [field, , { setValue }] = useField<ParsedValue>({
    name: props.name,
    validate,
  });
  const handleChange = React.useCallback(setValue, [setValue]);

  const minLabel = props.formatLabel
    ? props.formatLabel(field.value.min)
    : null;

  const maxLabel = props.formatLabel
    ? props.formatLabel(field.value.max)
    : null;

  return (
    <StyledRangeInput className="field field--range">
      <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
      <RangeComponent
        handle={SliderHandle}
        value={props.onFormat(field.value)}
        min={props.min}
        max={props.max}
        onChange={value => handleChange(props.onParse(value))}
        railStyle={{ height: 7 }}
      />
      <p>
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </p>
      <ErrorMessage component={StyledErrorMessage} name={props.name} />
    </StyledRangeInput>
  );
};
