import { ModalComponents } from './modal-container/modal-container';
import { ApplyModal } from './modals/apply';
import { InquiryModal } from './modals/inquiry';
import { NavigationModal } from './modals/navigation';

export const modals: ModalComponents = {
  apply: ApplyModal,
  inquiry: InquiryModal,
  nav: NavigationModal,
};
