import * as Formidable from 'formidable';
import { isValidResume } from './validation-helpers';

export type ValidationCallback<TValue> = (
  value: TValue
) => string | void | undefined;

const parsePrefix = (key: string, prefix = '') => {
  if (prefix) {
    key = `${prefix}[${key}]`;
  }

  return key;
};

export const parseFormData = (formData: FormData, data: any, prefix = '') => {
  for (const key in data) {
    // is object
    if (
      data[key] &&
      typeof data[key] === 'object' &&
      !(data[key] instanceof File)
    ) {
      parseFormData(formData, data[key], parsePrefix(key, prefix));
    } else {
      formData.append(
        parsePrefix(key, prefix),
        typeof data[key] !== 'undefined' && data[key] !== null ? data[key] : ''
      );
    }
  }
};

export const selectParsedFormData = (inputData: object) => {
  const formData = new FormData();

  parseFormData(formData, inputData);

  return formData;
};

export const parseFetchPostData = (inputData: object) => {
  return {
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
    body: inputData && selectParsedFormData(inputData),
  };
};

export const throwIfBadHttpResponse = (response: Response) => {
  if (response.status < 200 || response.status >= 300) {
    throw new Error('Bad HTTP Response');
  }
};
