import {
  parseFetchPostData,
  throwIfBadHttpResponse,
} from '../helpers/formHelpers';
import {
  ApplyRequestApiModel,
  ApplyResponseApiModel,
} from '../../api/api-models';

export const submitApplication = async (
  request: ApplyRequestApiModel
): Promise<ApplyResponseApiModel> => {
  const res = await fetch('/api/apply', parseFetchPostData(request));
  throwIfBadHttpResponse(res);

  return await res.json();
};
