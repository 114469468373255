import React from 'react';
import { IUrlProperties } from './link-provider.helpers';

export interface ILinkProviderContext {
  internalPaths?: string[];
  selectUrlProperties: (url: string) => IUrlProperties;
}

export const LinkProviderContext = React.createContext<
  ILinkProviderContext | undefined
>(undefined);

export const useLinkProviderContext = () => {
  const linkProviderContext = React.useContext(LinkProviderContext);

  if (!linkProviderContext) {
    throw new Error('LinkProviderContext used outside of a LinkProvider');
  }

  return linkProviderContext;
};
