import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { layers } from '../../abstracts/theme.styles';
import {
  colourShiftBackgroundLoad,
  baseEasingFunction,
  transitionTiming,
  dotRotateOne,
  dotRotateTwo,
} from '../../abstracts/animation.styles';

export const StyledLoad = styled.div`
  position: fixed;
  z-index: ${layers.pageLoad};
  width: 100%;
  height: 100%;
  border-width: 40px 30px;
  border-style: solid;
  border-color: transparent;
  overflow: hidden;

  &.loader {
    opacity: 0;
    pointer-events: none;
    animation: none;

    &--visible {
      opacity: 1;
      pointer-events: all;
      animation: ${colourShiftBackgroundLoad} ${transitionTiming('loader')}
        ${baseEasingFunction} forwards;
    }
  }

  @media ${media.tabletLandscape} {
    border-width: 60px;
  }

  .load__pattern {
    position: absolute;
    left: -62.5%;
    top: -62.5%;
    width: 225%;
    height: 225%;
    transform-origin: center;
    will-change: transform;

    & svg {
      position: absolute;
      left: -250%;
      top: -125%;
      width: 500%;
      height: 250%;
      max-width: none;
      max-height: none;

      @media (orientation: landscape) {
        left: -125%;
        top: -250%;
        width: 250%;
        height: 500%;
      }
    }

    &__container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .load__pattern--one {
        animation: ${dotRotateOne} ${transitionTiming('loader')}
          ${baseEasingFunction} forwards;
      }

      .load__pattern--two {
        animation: ${dotRotateTwo} ${transitionTiming('loader')}
          ${baseEasingFunction} forwards;
      }
    }
  }
`;
