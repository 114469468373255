import styled from 'styled-components';
import { animation } from '../../../abstracts/animation.styles';
import { media } from '../../../abstracts/media-settings.styles';
import { paragraphStyles } from '../../../abstracts/typography.styles';

export const StyledModalContent = styled.div`
  .menu {
    ${paragraphStyles}
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    margin-bottom: 0;

    @media ${media.tabletLandscape} {
      margin-top: 135px;
      justify-content: right;
      align-items: flex-end;
      height: auto;
    }

    a {
      border-bottom: 0;
      transition: ${animation.hover};

      &:hover {
        color: ${props => props.theme.secondaryOpaque};
      }
    }

    li + li {
      margin-top: 20px;
    }
  }
`;
