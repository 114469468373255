import * as React from 'react';
import {
  LinkProviderContext,
  ILinkProviderContext,
} from './link-provider.context';
import { useLocation } from '@reach/router';
import {
  selectUrlProperties as selectUrlPropertiesInternal,
  IUrlPropertiesOptions,
} from './link-provider.helpers';

export interface ILinkProviderProps {
  internalPaths?: ILinkProviderContext['internalPaths'];
  onFinalizeProperties?: IUrlPropertiesOptions['onFinalizeProperties'];
}

export const LinkProvider: React.FC<ILinkProviderProps> = props => {
  const location = useLocation();

  const selectUrlProperties = React.useCallback(
    (url: string) =>
      selectUrlPropertiesInternal(url, {
        onFinalizeProperties: props.onFinalizeProperties,
        currentPath: location.href,
        internalPaths: props.internalPaths,
      }),
    [location, props.internalPaths, props.onFinalizeProperties]
  );

  const maybeHandleCmsLink = React.useCallback(
    (event: MouseEvent) => {
      let anchor: HTMLAnchorElement | undefined;
      let target = event.target;

      if (event.defaultPrevented) {
        return;
      }

      while (target instanceof Node && !anchor) {
        if (target instanceof HTMLAnchorElement) {
          anchor = target;
        }

        target = target.parentNode;
      }

      if (anchor instanceof HTMLAnchorElement) {
        let shouldPreventDefault = false;
        const urlProperties = selectUrlProperties(anchor.href);

        if (urlProperties.isExternal || urlProperties.isFile) {
          shouldPreventDefault = true;
          window.open(anchor.href, '__blank');
        } else if (urlProperties.isNavigable || urlProperties.isHash) {
          shouldPreventDefault = true;
          // todo: this isn't a supported Gatsby API,
          // but it's what GatsbyLink uses.
          (window as any).___navigate(anchor.href, location.state || {});
        }

        if (shouldPreventDefault) {
          event.preventDefault();
        }
      }
    },
    [selectUrlProperties, location]
  );

  React.useEffect(() => {
    document.addEventListener('click', maybeHandleCmsLink);

    return () => document.removeEventListener('click', maybeHandleCmsLink);
  }, [maybeHandleCmsLink]);

  return (
    <LinkProviderContext.Provider
      value={{
        selectUrlProperties,
        internalPaths: props.internalPaths,
      }}
    >
      {props.children}
    </LinkProviderContext.Provider>
  );
};
