import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { color } from '../../abstracts/variables/colors.styles';
import { StyledCmsContent } from './cms-content.styles';
import { animation } from '../../abstracts/animation.styles';
import { layers } from '../../abstracts/theme.styles';

export const StyledSpecialMessage = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-top: 36px;
  padding-bottom: 34px;
  opacity: 1;
  background-color: ${color.cobalt};
  z-index: ${layers.specialMessage};
  transition: opacity ${animation.specialMessage};

  @media screen and (-ms-high-contrast: none) and (min-width: 1024px),
    screen and (-ms-high-contrast: active) and (min-width: 1024px) {
    position: fixed;
  }

  &.enter-active {
    opacity: 1;
  }

  &.exit-active,
  &.exit-done,
  &.enter {
    opacity: 0;
  }

  ${StyledCmsContent} {
    display: inline-block;
    width: calc(100% - 25px);
    padding-right: 5px;

    p {
      margin: 0;
    }
  }

  .special-message {
    &__close-button {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: auto;
      fill: ${props => props.theme.secondary};
      opacity: 1;
      vertical-align: top;

      &:hover {
        opacity: 0.3;
      }

      clipPath {
        transform: rotate(45deg) translate(0, 0);
        transform-origin: center;
      }

      .menu-clip__shape {
        transform: scale(1);

        &--one {
          transform-origin: top right;
        }

        &--two {
          transform-origin: bottom left;
        }
      }

      .menu__bar {
        &--top {
          fill: ${props => props.theme.secondary};
          transform: rotate(45deg) translate(4px, -7px);
          clip-path: url(#menu-clip);
        }

        &--bottom {
          fill: ${props => props.theme.secondary};
          transform: rotate(-45deg) translate(17px, -14px) scaleX(2);
          transform-origin: right top;
        }
      }
    }
  }
`;
