import styled from 'styled-components';
import {
  LabelTextStyles,
  smallParagraphStyles,
} from '../../abstracts/typography.styles';
import { animation } from '../../abstracts/animation.styles';

export const StyledFileInput = styled.div`
  ${smallParagraphStyles}
  position: relative;

  &:focus-within,
  &:hover {
    label {
      color: ${props => props.theme.secondaryOpaque};
    }

    svg {
      fill: ${props => props.theme.secondaryOpaque};
    }
  }

  &.field--error {
    color: ${props => props.theme.error};

    svg {
      fill: ${props => props.theme.error};
    }
  }

  input {
    ${LabelTextStyles}
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    ${LabelTextStyles}
    display: block;
    cursor: pointer;
    transition: color ${animation.hover};
  }

  svg {
    display: inline-block;
    position: relative;
    top: 8px;
    width: 14px;
    height: 15px;
    margin-left: 5px;
    fill: ${props => props.theme.secondary};
    transition: fill ${animation.hover};
  }
`;
