import React from 'react';
import {
  CheckboxOption,
  CheckboxGroupInput,
} from '../form-inputs/checkbox-input';
import { RangeInput } from '../form-inputs/range-input';
import { Formik, Form } from 'formik';
import { TextInput } from '../form-inputs/text-input';
import { submitInquiry } from '../../actions/submitInquiry';
import {
  InquiryRequestApiModel,
  TIMEFRAME_RANGE,
  BUDGET_RANGE,
} from '../../../api/api-models';
import { ModalContent } from './parts/modal-content';
import { FormWrapper } from '../forms/form-wrapper';
import { FormContent } from '../forms/form-content';
import { FormSuccess } from '../forms/form-success';
import { FormError } from '../forms/form-error';
import { Heading1, StyledLabel } from '../../abstracts/typography.styles';
import { SubmitButton } from '../form-inputs/submit';
import { EmailInput } from '../form-inputs/email-input';
import {
  rangeToIntArrayValueConverter,
  intArrayToRangeValueConverter,
} from '../../helpers/input-helpers';
import { FormGroup } from '../forms/form-group';

const keywords = [
  'Strategy',
  'Advertising',
  'Digital Design',
  'Ecommerce',
  'Social Media',
  'Content',
  'Technology',
  'ROI/Analytics',
  'Something Else',
];

const keywordOptions: CheckboxOption<string>[] = keywords.map(option => ({
  value: option,
}));

const initialValues: InquiryFormValues = {
  description: '',
  keywords: [],
  timeframe: {
    min: 1,
    max: 18,
  },
  budget: {
    min: 20,
    max: 150,
  },
  name: '',
  email: '',
  organization: '',
};

export interface InquiryFormValues extends InquiryRequestApiModel {}

export const InquiryModal: React.FC = () => (
  <ModalContent theme="light">
    <FormWrapper>
      {formContext => (
        <>
          <FormContent>
            <Heading1 as="h2">Inquiry</Heading1>
            <Formik
              initialValues={initialValues}
              onSubmit={async values => {
                formContext.beforeSubmit();

                try {
                  await submitInquiry(values);

                  formContext.onSuccess();
                } catch (e) {
                  console.log(e);

                  formContext.onError();
                }
              }}
            >
              <Form noValidate>
                <FormGroup title="01 — Tell us about your project.">
                  <TextInput
                    type="textarea"
                    name="description"
                    label="Tell us about your project."
                  />
                </FormGroup>
                <FormGroup title="02 — Time and budget range?">
                  <RangeInput
                    name="timeframe"
                    label="Timeframe"
                    min={TIMEFRAME_RANGE.min}
                    max={TIMEFRAME_RANGE.max}
                    formatLabel={value =>
                      value === 1
                        ? `${value} month`
                        : value === 24
                        ? `More than ${value} months`
                        : `${value} months`
                    }
                    onFormat={rangeToIntArrayValueConverter}
                    onParse={intArrayToRangeValueConverter}
                  />
                  <RangeInput
                    name="budget"
                    label="Budget"
                    min={BUDGET_RANGE.min}
                    max={BUDGET_RANGE.max}
                    formatLabel={value => `$${value}k`}
                    onFormat={rangeToIntArrayValueConverter}
                    onParse={intArrayToRangeValueConverter}
                  />
                </FormGroup>
                <FormGroup title="03 — Your contact information">
                  <TextInput name="name" label="Name" />
                  <EmailInput name="email" label="Email" />
                  <TextInput name="organization" label="Organization" />
                </FormGroup>
                <SubmitButton text="Send Inquiry" />
              </Form>
            </Formik>
          </FormContent>
          <FormSuccess>
            <h1>Thanks for your inquiry.</h1>
          </FormSuccess>
          <FormError />
        </>
      )}
    </FormWrapper>
  </ModalContent>
);
