import {
  MenuFragmentFragment,
  SiteMetaFragmentFragment,
} from '../../types/gatsby-types';
import React from 'react';
import { graphql } from 'gatsby';
import { ILayoutContext } from './layout/layout';

export const SiteMetaFragment = graphql`
  fragment SiteMetaFragment on MetaJson {
    fallbackSeo {
      image {
        ...FallbackImageFragment
      }
      description
      keywords
      title
    }
    pageSettings {
      footerMenuSlug
      mainMenuSlug
      workPageId
    }
    fbAppId
  }
`;

export type ISiteMetaContext = SiteMetaFragmentFragment &
  ILayoutContext & {
    mainMenu: MenuFragmentFragment;
  };

export const SiteMetaContext = React.createContext<ISiteMetaContext | null>(
  null
);

export const useSiteMetaContext = () => {
  const siteMetaContext = React.useContext(SiteMetaContext);

  if (!siteMetaContext) {
    throw new Error(
      'SiteMeta Context used outside of SiteMetaContext.Provider'
    );
  }

  return siteMetaContext;
};
