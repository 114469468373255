import styled, { css } from 'styled-components';
import { font } from './variables/fonts.styles';
import { media } from '../abstracts/media-settings.styles';

export const h1Styles = css`
  font-family: ${font.secondary};
  font-size: ${font.sizeMega};
  line-height: 55px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeGiga};
    line-height: 75px;
  }
`;

export const h2Styles = css`
  font-family: ${font.secondary};
  font-size: ${font.sizeAlpha};
  line-height: 75px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeMega};
  }
`;

export const h3Styles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.sizeBeta};

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeAlpha};
  }
`;

export const h4Styles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.sizeGamma};

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeBeta};
  }
`;

export const h5Styles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.sizeDelta};

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeGamma};
  }
`;

export const LinkStyles = css`
  font-family: ${font.primary};
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.secondary};
  color: ${props => props.theme.secondary};
  transition: border-bottom 0.2s linear;

  &:hover {
    border-bottom: 1px solid ${props => props.theme.secondaryOpaque};
  }
`;

export const specialtyStyles = css`
  font-family: ${font.secondary};
  font-size: ${font.sizeZeta};
  line-height: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeEpsilon};
    letter-spacing: 2.5px;
    line-height: 11px;
  }
`;

export const boldParagraph = css`
  font-family: ${font.primaryBold};
  font-size: ${font.sizeBeta};
  line-height: 30px;
  margin-bottom: 20px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeAlpha};
    line-height: 35px;
    margin-bottom: 25px;
  }
`;

export const paragraphStyles = css`
  font-family: ${font.primary};
  font-size: ${font.sizeBeta};
  line-height: 30px;
  margin-bottom: 20px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeAlpha};
    line-height: 35px;
    margin-bottom: 25px;
  }
`;

export const smallParagraphTextStyles = css`
  font-family: ${font.primary};
  font-size: ${font.sizeDelta};
  line-height: 25px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeGamma};
    line-height: 30px;
  }
`;

export const smallParagraphStyles = css`
  ${smallParagraphTextStyles}
  margin-bottom: 16px;

  @media ${media.tabletLandscape} {
    margin-bottom: 18px;
  }
`;

export const bragParagraphStyles = css`
  font-family: ${font.primary};
  font-size: ${font.sizeMega};
  line-height: 55px;
`;

export const LabelTextStyles = css`
  font-family: ${font.primary};
  font-size: ${font.sizeBeta};
  line-height: 30px;
`;

export const InputLabelFocusStyles = css`
  font-family: ${font.secondary};
  font-size: ${font.sizeZeta};
  line-height: 13px;
  margin-bottom: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media ${media.tabletLandscape} {
    line-height: 30px;
  }
`;

export const Heading1 = styled.h1`
  ${h1Styles}
`;

export const Heading2 = styled.h2`
  ${h2Styles}
`;

export const Heading3 = styled.h3`
  ${h3Styles}
`;

export const Heading4 = styled.h4`
  ${h4Styles}
`;

export const Heading5 = styled.h5`
  ${h5Styles}
`;

export const StyledParagraph = styled.p`
  ${smallParagraphStyles}
`;

export const StyledLargeParagraph = styled.p`
  ${paragraphStyles}
`;

export const StyledLabel = styled.label`
  ${LabelTextStyles}
  display: block;
  margin-bottom: 18px;

  @media ${media.tabletLandscape} {
    margin-bottom: 22px;
  }
`;

export const StyledBoldParagraph = styled.p`
  ${boldParagraph}
`;

export const unorderedList = css`
  font-family: ${font.primary};
  font-size: ${font.sizeDelta};
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 22px;
  margin-left: 16px;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeGamma};
    line-height: 25px;
    margin-bottom: 1.5em;
  }

  li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      height: 4px;
      width: 4px;
      background-color: ${props => props.theme.secondary};
      opacity: 0.7;
      border-radius: 50%;
      left: -16px;
      top: 10px;
    }
  }
`;

export const orderedList = css`
  font-family: ${font.primary};
  font-size: ${font.sizeDelta};
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 22px;
  margin-left: 16px;
  counter-reset: step-counter;

  @media ${media.tabletLandscape} {
    font-size: ${font.sizeGamma};
    line-height: 25px;
  }

  li {
    position: relative;
    margin-bottom: 5px;

    &::before {
      position: absolute;
      bottom: 2px;
      left: -16px;
      font-size: 13px;
      content: counter(step-counter) ')';
      counter-increment: step-counter;
    }
  }
`;

export const screenReader = css`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
`;
