import React from 'react';
import classNames from 'classnames';
import {
  ErrorMessage,
  Field,
  FieldProps,
  FormikProps,
  useField,
  useFormikContext,
} from 'formik';
import { validateResume } from '../../helpers/validation-helpers';
import { ValidationCallback } from '../../helpers/formHelpers';
import { StyledFileInput } from './file-input.styles';
import { StyledLabel } from '../../abstracts/typography.styles';
import UploadSvg from '../../svg/uploadSvg.svg';
import { StyledErrorMessage } from './error-message-wrapper.styles';

export interface IFileInputProps {
  name: string;
  label: string;
  className?: string;
  required?: boolean;
  validate?: ValidationCallback<File | ''>;
  accept?: string;
}

export const FileInput: React.FC<IFileInputProps> = props => {
  const required = props.required !== false;

  const defaultResumeValidator = React.useCallback(
    validateResume(props.required),
    [props.required]
  );

  const handleChange = (formik: FormikProps<any>) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formik.setFieldValue(
      event.target.name,
      event.target.files && event.target.files[0] ? event.target.files[0] : ''
    );
  };

  const [field, meta, helpers] = useField({
    name: props.name,
    validate: props.validate ?? defaultResumeValidator,
  });
  const error = meta.touched && meta.error;

  return (
    <StyledFileInput
      className={classNames(
        'field',
        'field--file',
        `${error ? 'field--error' : ''}`
      )}
    >
      <Field
        name={props.name}
        validate={props.validate ?? defaultResumeValidator}
      >
        {({ form, field }: FieldProps) => (
          <input
            type="file"
            name={props.name}
            required={required}
            onBlur={field.onBlur}
            onChange={handleChange(form)}
            accept={props.accept}
            id={props.name}
          />
        )}
      </Field>
      <StyledLabel htmlFor={props.name}>
        {props.label} <UploadSvg />
      </StyledLabel>
      <p>{field.value.name}</p>
      <ErrorMessage component={StyledErrorMessage} name={props.name} />
    </StyledFileInput>
  );
};
