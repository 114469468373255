import React from 'react';
import { StyledLoad } from './load.styles';
import DotsDeux from '../../svg/dots-deux.svg';
import { useWindowContext } from '../in-view/window-provider.context';
import classNames from 'classnames';
import { loaderTransitionTimingValue } from '../../abstracts/animation.styles';

export interface ILoadProps {
  is404Page: boolean;
}

export const Load: React.FC<ILoadProps> = props => {
  const $html = React.useRef<HTMLElement | null>(null);
  const bodyClass = 'loader-active';
  const [transitionComplete, setTransitionComplete] = React.useState(
    props.is404Page
  );
  const [isVisible, setIsVisible] = React.useState(!props.is404Page);
  const { transitioningElementsState } = useWindowContext();

  React.useLayoutEffect(() => {
    if (!props.is404Page) {
      if (typeof document !== 'undefined') {
        $html.current = document.documentElement;
        $html.current.classList.add(bodyClass);
      }
      const timeout = setTimeout(
        () => setTransitionComplete(true),
        loaderTransitionTimingValue
      );
      return () => clearTimeout(timeout);
    }
  }, []);

  React.useLayoutEffect(() => {
    if (isVisible && transitionComplete && transitioningElementsState === 0) {
      setIsVisible(false);
      if ($html.current) {
        $html.current?.classList.remove(bodyClass);
      }
    }
  }, [isVisible, transitionComplete, transitioningElementsState]);

  return (
    <StyledLoad
      className={classNames(
        'loader',
        transitionComplete && 'loader--complete',
        isVisible && 'loader--visible'
      )}
    >
      {props.children}
      <div className="load__pattern__container">
        <div className="load__pattern load__pattern--one">
          <DotsDeux />
        </div>
        <div className="load__pattern load__pattern--two">
          <DotsDeux />
        </div>
      </div>
    </StyledLoad>
  );
};
