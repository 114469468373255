import { Range } from '../src/helpers/input-helpers';

export const TIMEFRAME_RANGE: Range = {
  min: 1,
  max: 24,
};

export const BUDGET_RANGE: Range = {
  min: 1,
  max: 200,
};

export interface InquiryRequestApiModel {
  name: string;
  email: string;
  organization: string;
  keywords: string[];
  description: string;
  timeframe: Range;
  budget: Range;
}

export interface InquiryResponseApiModel {
  success: boolean;
}

export type ApplyRequestApiModel = {
  position: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  resume: File | '';
} & Record<string, string>;

export interface ApplyResponseApiModel {
  success: boolean;
}
