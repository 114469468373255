/* fun with fonts */
export const font = {
  primary: `'Whyte', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  primaryBold: `'Whyte Bold', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  primaryItalic: `'Whyte Italic', arial, 'Helvetica Neue', Helvetica, sans-serif`,
  secondary: `'Whyte Inktrap', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,

  // Sizes
  sizeGiga: '70px',
  sizeMega: '50px',
  sizeAlpha: '25px',
  sizeBeta: '20px',
  sizeGamma: '18px',
  sizeDelta: '16px',
  sizeEpsilon: '13px',
  sizeZeta: '11px',
};
