import * as React from 'react';

export interface IPageTransitionContext {
  onStart: () => void;
  onEnd: () => void;
  isTransitioning?: boolean;
}

export const PageTransitionContext = React.createContext<
  IPageTransitionContext | undefined
>(undefined);

export const usePageTransitionContext = () => {
  const pageTransitionContext = React.useContext(PageTransitionContext);

  if (!pageTransitionContext) {
    throw new Error('Modal Context used outside of a ModalContainer');
  }

  return pageTransitionContext;
};

export const PageTransitionContextProvider: React.FC = props => {
  const [isTransitioning, setTransitionState] = React.useState(false);

  const onStart = React.useCallback(() => {
    setTransitionState(true);
  }, []);
  const onEnd = React.useCallback(() => {
    setTransitionState(false);
  }, []);

  return (
    <PageTransitionContext.Provider
      value={{
        onStart,
        onEnd,
        isTransitioning,
      }}
    >
      {props.children}
    </PageTransitionContext.Provider>
  );
};
