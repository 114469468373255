import { Handle } from 'rc-slider';
import * as React from 'react';
import 'rc-slider/assets/index.css';
import ToggleSvg from '../../../svg/toggleSvg.svg';
import { StyledHandle } from './slider-handle.styles';

export const SliderHandle: React.FC<Handle> = props => {
  return (
    <StyledHandle
      as={Handle}
      key={(props as any).index}
      vertical={false}
      offset={0}
      className={''}
      {...{
        ...props,
        dragging: (props as any).dragging.toString(),
      }}
    >
      <ToggleSvg />
    </StyledHandle>
  );
};
