import React from 'react';
import classnames from 'classnames';
import {
  MenuFragmentFragment,
  WorkFragmentFragment,
  PageFragmentFragment,
} from '../../../types/gatsby-types';
import { graphql } from 'gatsby';
import { filterNotEmpty } from '../../helpers/arrayHelpers';
import { StyledLink } from './link.styles';

export interface INavMenuProps {
  className?: string;
  menu: MenuFragmentFragment;
}

export const MenuFragment = graphql`
  fragment MenuFragment on Nmbl_Menu {
    menuItemsList {
      menuItems {
        ... on Nmbl_LinkMenuItem {
          linkMenuItem {
            name
            url
          }
          page {
            contentItems {
              ... on Nmbl_Page {
                alias {
                  alias
                }
              }
              ... on Nmbl_Work {
                alias {
                  alias
                }
              }
            }
          }
        }
      }
    }
    alias {
      alias
    }
    contentItemId
  }
`;

export const NavMenu: React.FC<INavMenuProps> = props => (
  <ul
    className={classnames(
      'menu',
      props.className && `menu--${props.className}`
    )}
  >
    {props.menu.menuItemsList?.menuItems
      ?.filter(filterNotEmpty)
      .map(menuItem => {
        const page = menuItem.page?.contentItems?.find(Boolean) as
          | Pick<WorkFragmentFragment | PageFragmentFragment, 'alias'>
          | undefined;

        const alias = page && page.alias?.alias;

        return (
          <li key={menuItem.linkMenuItem?.name}>
            <StyledLink
              to={alias ? `/${alias}` : menuItem.linkMenuItem?.url ?? ''}
            >
              {menuItem.linkMenuItem?.name}
            </StyledLink>
          </li>
        );
      })}
  </ul>
);
