import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { GatsbyLinkProps } from 'gatsby';

export const Link = React.forwardRef<HTMLAnchorElement, GatsbyLinkProps<any>>(
  ({ to, ...props }, ref) => {
    return (
      <a href={to} ref={ref} {...props}>
        {props.children}
      </a>
    );
  }
);
