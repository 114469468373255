import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { useField, Field, ErrorMessage } from 'formik';
import { StyledTextInput } from './text-input.styles';
import { ValidationCallback } from '../../helpers/formHelpers';
import { validateText } from '../../helpers/validation-helpers';
import { StyledLabel } from '../../abstracts/typography.styles';
import { StyledErrorMessage } from './error-message-wrapper.styles';

export interface TextInputProps {
  name: string;
  label: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  className?: string;
  required?: boolean;
  validate?: ValidationCallback<string>;
}

export const TextInput: React.FC<TextInputProps> = props => {
  const required = props.required !== false;
  const defaultValidator = React.useCallback(
    validateText(props.name, required),
    [props.label, required]
  );

  const [field, meta, helpers] = useField({
    name: props.name,
    validate: props.validate ?? defaultValidator,
  });
  const error = meta.touched && meta.error;

  const hasValue = meta.value && meta.value.length > 0;

  return (
    <StyledTextInput
      className={classNames(
        'field',
        `field--${props.type || 'text'}`,
        `${hasValue ? 'field--has-value' : ''}`,
        `${error ? 'field--error' : ''}`
      )}
    >
      {props.type === 'textarea' ? (
        <Field
          as="textarea"
          name={props.name}
          required={required}
          validate={props.validate ?? defaultValidator}
        />
      ) : (
        <Field
          type={props.type}
          name={props.name}
          required={required}
          validate={props.validate ?? defaultValidator}
          placeholder={props.label}
        />
      )}
      <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
      <ErrorMessage
        component={StyledErrorMessage}
        name={props.name}
      ></ErrorMessage>
    </StyledTextInput>
  );
};
