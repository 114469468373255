import * as React from 'react';
import {
  FormStatus,
  FormWrapperContext,
  IFormWrapperContext,
} from './form-wrapper.context';
import { TransitionGroup, SwitchTransition } from 'react-transition-group';
import { isFunction } from 'lodash';
import { ModalContext } from '../modal-container/modal-container.context';
import { useEventCallback } from '../../helpers/hooks';
import {
  formSubmitEvent,
  formSuccessEvent,
} from '../../helpers/analyticsHelpers';
import { useLocation } from '@reach/router';

export interface IFormWrapperProps {
  children:
    | React.ReactElement
    | ((context: IFormWrapperContext) => React.ReactElement);
}

export const FormWrapper: React.FC<IFormWrapperProps> = props => {
  const [status, setStatus] = React.useState(FormStatus.Filling);
  const modalContext = React.useContext(ModalContext);
  const location = useLocation();

  const onSuccess = useEventCallback(
    (shouldClose: boolean = true) => {
      setStatus(FormStatus.Success);

      if (shouldClose) {
        setTimeout(() => modalContext?.onClose(), 7000);
      }

      formSuccessEvent(location);
    },
    [modalContext]
  );

  const beforeSubmit = useEventCallback(() => {
    formSubmitEvent(location);
  }, [modalContext]);

  const onError = React.useCallback((shouldClose = true) => {
    setStatus(FormStatus.Error);
  }, []);

  const value = { status, setStatus, beforeSubmit, onSuccess, onError };

  return (
    <FormWrapperContext.Provider value={value}>
      <TransitionGroup>
        <SwitchTransition>
          {isFunction(props.children) ? props.children(value) : props.children}
        </SwitchTransition>
      </TransitionGroup>
    </FormWrapperContext.Provider>
  );
};
