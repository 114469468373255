export const getRandomInt = (minOrMax: number, max?: number) => {
  let min = max ? minOrMax : 0;
  max = max ? max : minOrMax;
  return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
};

export const getRandomArrayItem = <T extends any>(array: T[]) => {
  const index = getRandomInt(array.length);

  return array[index];
};

export const chunkArray = <T extends any>(value: T[], chunkSize: number) => {
  // copy it!
  value = [...value];
  var results = [];

  while (value.length) {
    results.push(value.splice(0, chunkSize));
  }

  return results;
};

export const filterNotEmpty = <TValue extends any>(
  value: TValue | null | undefined
): value is TValue => !!value;

export const sortByString = (a: string, b: string) => a.localeCompare(b);

export const sortByDescending = <TValue>(
  selector?: (a: TValue, b: TValue) => number
) => (a: TValue, b: TValue) => {
  return -1 * (selector ? selector(a, b) : (a as any) - (b as any));
};

export const sortBySelected = <TValue>(selector: (value: TValue) => number) => (
  a: TValue,
  b: TValue
) => selector(a) - selector(b);

export const sortBySelectedString = <TValue>(
  selector: (value: TValue) => string,
  comparer?: (a: string, b: string) => number
) => (a: TValue, b: TValue) =>
  (comparer || sortByString)(selector(a), selector(b));
