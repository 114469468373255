import React from 'react';
import { IInViewElement } from './in-view-container';

export interface IInViewContext {
  registerElement: (element: IInViewElement) => void;
  updateElement: (element: IInViewElement) => void;
}

export const InViewContext = React.createContext<IInViewContext | undefined>(
  undefined
);

export const useInViewContext = () => {
  const inViewContext = React.useContext(InViewContext);

  if (!inViewContext) {
    throw new Error('In View Context used outside of a InViewContainer');
  }

  return inViewContext;
};
