/// <reference types="google.analytics" />
import { WindowLocation } from '@reach/router';

export const doesGAExist = () => {
  return window && window.ga;
};

export const getGAPagePath = (location: WindowLocation) => {
  return location
    ? location.pathname + location.search + location.hash
    : undefined;
};

export const pageViewEvent = (location: WindowLocation) => {
  if (doesGAExist()) {
    const pagePath = getGAPagePath(location);
    window.ga(`set`, `page`, pagePath);
    window.ga(`send`, `pageview`);
  }
};

export const modalViewEvent = (location: WindowLocation) => {
  if (doesGAExist()) {
    const pagePath = getGAPagePath(location);
    window.ga('send', 'event', 'Modal', 'open', pagePath);
  }
};

export const formSubmitEvent = (location: WindowLocation) => {
  if (doesGAExist()) {
    const pagePath = getGAPagePath(location);
    window.ga('send', 'event', 'Form', 'submit', pagePath);
  }
};

export const formSuccessEvent = (location: WindowLocation) => {
  if (doesGAExist()) {
    const pagePath = getGAPagePath(location);
    window.ga('send', 'event', 'Form', 'success', pagePath);
  }
};
