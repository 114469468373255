import styled from 'styled-components';
import { CmsContent } from '../parts/cms-content';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledCmsContent = styled(CmsContent)`
  font-family: ${font.primary};
  ${themedMarginTop('0', '80px')};
  ${themedMarginBottom('0', '60px')};

  @media ${media.tabletLandscape} {
    ${themedMarginTop('0', '100px')};
    ${themedMarginBottom('0', '120px')};
  }
`;
