import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';

export const StyledRangeInput = styled.div`
  font-family: ${font.primary};
  color: ${props => props.theme.secondary};

  .rc-slider-rail {
    border: 1px solid ${props => props.theme.secondary};
    background-color: ${props => props.theme.primary};
  }

  .rc-slider-track {
    background-color: ${props => props.theme.secondary};
    height: 7px;
  }

  p {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  label {
    margin-bottom: 10px;
  }
`;
