import { graphql } from 'gatsby';
import React from 'react';
import { SpecialMessageFragmentFragment } from '../../../types/gatsby-types';
import { StyledWrapper } from '../parts/wrapper.styles';
import { StyledSpecialMessage } from './special-message.styles';
import { CmsContent } from './cms-content';
import MenuSvg from '../../svg/menuSvg.svg';
import { Link } from '../navigation/link';

export const SpecialMessageFragment = graphql`
  fragment SpecialMessageFragment on Nmbl_SpecialMessage {
    startTime
    endTime
    htmlBody {
      ...HtmlBodyFragment
    }
    contentItemVersionId
    modifiedUtc
  }
`;

export const SpecialMessage: React.FC<SpecialMessageFragmentFragment & {
  onClose: Function;
}> = props => {
  const handleClose = React.useCallback<React.MouseEventHandler>(
    event => {
      event.preventDefault();
      props.onClose(props.contentItemVersionId);
    },
    [props.onClose, props.contentItemVersionId]
  );

  return (
    <StyledWrapper as={StyledSpecialMessage} className={'special-message'}>
      <CmsContent html={props?.htmlBody?.html} />
      <Link
        className="special-message__close-button"
        to="#"
        onClick={handleClose}
      >
        <MenuSvg />
      </Link>
    </StyledWrapper>
  );
};
