import React from 'react';
import { FormGroupTitle } from './form-group-header';
import { StyledFormGroup } from './form-group.styles';

export interface IFormGroupProps {
  title?: string;
}

export const FormGroup: React.FC<IFormGroupProps> = props => (
  <StyledFormGroup className="form__group">
    {props.title && <FormGroupTitle>{props.title}</FormGroupTitle>}
    {props.children}
  </StyledFormGroup>
);
