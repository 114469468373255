// Breakpoint sizes for media queries
export const size = {
  mobile: '375px',
  mobileLandscape: '480px',
  tablet: '768px',
  tabletLandscape: '1024px',
  desktop: '1440px',
  desktopLarge: '1920px',
};

export const media = {
  mobile: `(min-width: ${size.mobile})`,
  mobileLandscape: `(min-width: ${size.mobileLandscape})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletLandscape: `(min-width: ${size.tabletLandscape})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopLarge: `(min-width: ${size.desktopLarge})`,
  ieTabletLandscape: `screen and (-ms-high-contrast: none) and (min-width: 1024px),
  screen and (-ms-high-contrast: active) and (min-width: 1024px)`,
};
