import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import { color } from '../../abstracts/variables/colors.styles';
import { animation } from '../../abstracts/animation.styles';

export const StyledSubmit = styled.button`
  background-color: ${props => props.theme.secondary};
  width: 100vw;
  color: ${props => props.theme.primary};
  padding: 42px 0;
  margin: 0 auto;
  margin-left: -30px;
  display: block;
  font-family: ${font.secondary};
  font-size: ${font.sizeEpsilon};
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: background-color ${animation.hover};

  @media ${media.tablet} {
    width: 100%;
    margin-left: auto;
    margin-bottom: 197px;
  }

  @media ${media.tabletLandscape} {
    width: 100%;
    padding: 23px 0;
    margin-left: auto;
  }

  &:hover {
    background-color: ${color.ash};
  }
`;
