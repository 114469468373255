import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid12 } from '../../abstracts/theme.styles';
import { font } from '../../abstracts/variables/fonts.styles';
import { StyledFormGroup } from './form-group.styles';

export const StyledFormWrapper = styled.div`
  padding-top: 150px;

  @media ${media.tablet} {
    ${grid12}
  }

  h2 {
    margin-top: -7px;
    margin-bottom: 34px;
    font-size: ${font.sizeMega};

    @media ${media.tabletLandscape} {
      margin-bottom: 60px;
    }
  }
`;

export const StyledFormContent = styled.div`
  @media ${media.tablet} {
    grid-column: 3 / span 8;
  }

  @media ${media.tabletLandscape} {
    grid-column: 4 / span 6;
  }
`;

export const StyledForm = styled.div`
  p + form {
    margin-top: 47px;
  }

  .field + fieldset {
    margin-top: 57px;
  }

  fieldset + .field {
    margin-top: 50px;
  }

  .field + .field {
    margin-top: 83px;
  }

  .field + button[type='submit'] {
    margin-top: 50px;

    @media ${media.tablet} {
      margin-bottom: 197px;
    }
  }

  ${StyledFormGroup} {
    .field + fieldset {
      margin-top: 17px;
    }

    fieldset + .field {
      margin-top: 10px;
    }

    .field + .field {
      margin-top: 10px;

      &.field--range {
        margin-top: 32px;
      }
    }
  }
`;
