import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { screenReader } from '../../abstracts/typography.styles';
import { StyledRangeInput } from '../form-inputs/range-input.styles';

export const StyledFormGroup = styled.div`
  margin-bottom: 50px;

  textarea + label {
    ${screenReader}
  }
  ${StyledRangeInput} {
    padding-top: 6px;

    label {
      ${screenReader}
    }

    p {
      margin-top: 14px;
    }
  }
`;
