import styled from 'styled-components';
import { InViewElement } from './in-view-element';

export const StyledInViewElement = styled(InViewElement)`
  opacity: 0;

  html:not(.loader-active) & {
    transition: opacity 550ms ease;
  }

  &.in-view-element--visible {
    opacity: 1;
  }
`;
