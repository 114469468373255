export const REGEXP_YOUTUBE_WATCH = /^https?:\/\/(?:(?:m|www)\.)?youtube\.com\/watch\?v=(.+)$/i;
export const REGEXP_YOUTUBE_TINY = /^https?:\/\/youtu\.be\/(.+)$/i;

export interface IUrlProperties {
  /**
   * it is a URL that should trigger a page change
   */
  isNavigable: boolean;
  /**
   * it is an absolute URL. `https://carsense.com/` not `/`
   */
  isAbsolute: boolean;
  /**
   * it is a hash-only URL. `#hash` not `/#hash`
   */
  isHash: boolean;
  /**
   * it is an email including mailto: `mailto:web@johnrom.com`
   */
  isEmail: boolean;
  /**
   * it is an email including tel: `tel:+12672672672`
   */
  isPhone: boolean;
  /**
   * it is a youtube url matching one of the following:
   * @see REGEXP_YOUTUBE_WATCH
   * @see REGEXP_YOUTUBE_TINY
   */
  isVideo: boolean;
  /**
   * it is a file url ending in an extension
   */
  isFile: boolean;
  /**
   * it is an external URL, like Facebook
   */
  isExternal: boolean;
}

export interface IUrlPropertiesOptions {
  currentPath?: string;
  internalPaths?: string[];
  specialHashes?: string[];
  onFinalizeProperties?: (properties: IUrlProperties) => IUrlProperties;
}

export const selectSchemalessUrl = (url: string | undefined) => {
  return url ? url.replace(/^(https?:)?\/\//i, '') : '';
};

export const selectIsFile = (url: string) => {
  // remove domain from the equation
  url = url.replace(/^https?:\/\/[^/]+/, '');

  const lastSlashIndex = url.lastIndexOf('/');

  if (lastSlashIndex !== -1) {
    url = url.substr(lastSlashIndex + 1);
  }

  url = url.split('?')[0];
  url = url.split('#')[0];

  return /.*\.[a-z0-9]{2,4}$/i.test(url);
};

export const selectIsVideoUrl = (videoUrl?: string) => {
  return (
    videoUrl &&
    (REGEXP_YOUTUBE_WATCH.test(videoUrl) || REGEXP_YOUTUBE_TINY.test(videoUrl))
  );
};

export const selectUrlProperties = (
  url: string,
  options?: IUrlPropertiesOptions
): IUrlProperties => {
  const schemalessUrl = selectSchemalessUrl(url);
  const isPhone = url.indexOf('tel:') === 0;
  const isEmail = url.indexOf('mailto:') === 0;

  const isAbsolute = url.indexOf('://') !== -1;
  const isExternal =
    isAbsolute &&
    !!options?.internalPaths &&
    !options.internalPaths.some(internalPath => {
      return schemalessUrl.indexOf(selectSchemalessUrl(internalPath)) === 0;
    });
  const isVideo = isExternal && !!selectIsVideoUrl(url);
  const isHash =
    !isExternal &&
    (url.indexOf('#') === 0 ||
      (!!options?.currentPath &&
        url.indexOf(options.currentPath + '#') !== -1));

  let isFile = !isEmail && selectIsFile(url);

  const urlProperties: IUrlProperties = {
    isNavigable:
      !isEmail && !isExternal && !isFile && !isHash && !isPhone && !isVideo,
    isAbsolute,
    isEmail,
    isExternal,
    isFile,
    isHash,
    isPhone,
    isVideo,
  };

  return options?.onFinalizeProperties
    ? options.onFinalizeProperties(urlProperties)
    : urlProperties;
};
