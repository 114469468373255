import { getRandomArrayItem } from '../../helpers/arrayHelpers';

const signoffs = [
  'splendid',
  'majestic',
  'fantastic',
  'phenomenal',
  'splendid',
  'sensational',
  'magnificent',
  'prodigious',
  'remarkable',
  'stupendous',
  'tremendous',
  'wondrous',
  'colossal',
  'dynamite',
];

export const selectSignOff = () => {
  var date = new Date();

  return `Have a ${getRandomArrayItem(
    signoffs
  )} ${date.toLocaleDateString('en-US', { weekday: 'long' })}.`;
};
