import styled from 'styled-components';
import { font } from '../../abstracts/variables/fonts.styles';
import { media } from '../../abstracts/media-settings.styles';
import { specialtyStyles } from '../../abstracts/typography.styles';

export const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};
  border-top: 1px solid ${props => props.theme.secondaryWhisper};
  padding-top: 155px;
  padding-bottom: 91px;

  /* quoth the raven */
  @media ${media.tabletLandscape} {
    padding-top: 108px;
    padding-bottom: 102px;
    flex-direction: row;
  }

  .footer__navigation {
    margin-bottom: 133px;

    @media ${media.tabletLandscape} {
      order: 3;
      margin-left: auto;
      margin-bottom: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      text-align: center;

      @media ${media.tabletLandscape} {
        flex-direction: row;
        text-align: left;
        padding-right: 16px;
      }
    }

    li,
    a {
      color: ${props => props.theme.secondary};
    }

    a {
      border-bottom: 0;
      font-size: 20px;

      &:hover {
        color: ${props => props.theme.secondaryOpaque};
      }
    }

    li {
      padding-top: 10px;
      padding-bottom: 15px;

      @media ${media.tabletLandscape} {
        padding-top: 0;
        padding-right: 25px;
        padding-bottom: 0;
        padding-left: 25px;
      }

      @media ${media.desktop} {
        padding-right: 50px;
        padding-left: 50px;
      }
    }
  }

  .footer__logo {
    display: inline-block;
    height: 30px;
    width: 30px;
    fill: ${props => props.theme.secondary};
    margin-bottom: 38px;

    @media ${media.tabletLandscape} {
      margin-right: 22px;
      margin-bottom: 0;
    }
  }

  .footer__signoff {
    ${specialtyStyles}
    color: ${props => props.theme.secondary};
  }
`;
