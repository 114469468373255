import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid12 } from '../../abstracts/theme.styles';

export const LayoutInner = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};

  .widget--MarqueeWidget + .widget--RecentWorkWidget,
  .widget--MarqueeWidget + .widget--WorkGridWidget {
    @media ${media.tabletLandscape} {
      margin-top: -80px;
    }

    @media ${media.desktop} {
      margin-top: -225px;
    }
  }

  .widget--CareersWidget + .widget--EditorWidget {
    .in-view-element {
      @media ${media.tabletLandscape} {
        ${grid12}
      }
    }

    .cms-content {
      @media ${media.tabletLandscape} {
        grid-column: auto / span 4;
        padding-top: 0;
      }
    }
  }

  .widget--ColumnsWidget + .widget--EditorWidget {
    margin-top: 120px;
  }

  .template--work {
    .widget--EditorWidget {
      .in-view-element {
        @media ${media.tabletLandscape} {
          ${grid12}
        }
      }

      .cms-content {
        @media ${media.tabletLandscape} {
          grid-column: auto / span 4;
        }
      }
    }

    .services {
      padding-top: 65px;
      padding-bottom: 156px;
      margin-top: 0;
      margin-bottom: 0;

      @media ${media.tabletLandscape} {
        padding-top: 101px;
        padding-bottom: 170px;
        margin-top: 0;
        margin-bottom: 0;
      }

      &__list {
        margin-bottom: 49px;
      }
    }
  }
`;
