import styled from 'styled-components';
import { HandleProps } from 'rc-slider';
import { animation } from '../../../abstracts/animation.styles';

export const StyledHandle = styled.div<HandleProps>`
  width: 30px;
  height: 30px;
  margin-top: -10px;
  border: 0;

  .circle {
    fill: ${props => props.theme.secondary};
    transition: fill ${animation.hover};

    &__outline {
      fill: ${props => props.theme.secondary};
    }
  }

  .lines {
    fill: ${props => props.theme.primary};
    transition: fill ${animation.hover};
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;

    .circle {
      fill: ${props => props.theme.primary};

      &__outline {
        fill: ${props => props.theme.secondary};
      }
    }

    .lines {
      fill: ${props => props.theme.secondary};
    }
  }
`;
