import React from 'react';
import { graphql } from 'gatsby';
import { CareerFragmentFragment } from '../../../types/gatsby-types';
import { CmsContent } from './cms-content';
import { StyledLink } from '../navigation/link.styles';
import { Heading3, StyledParagraph } from '../../abstracts/typography.styles';
import { StyledCareer } from './career.styles';
import { Drawer } from './drawer';
import { StyledInViewElement } from '../in-view/in-view-element.styles';
import { StyledCmsContent } from '../widgets/editor.styles';
import { filterNotEmpty } from '../../helpers/arrayHelpers';

export const CareerFragment = graphql`
  fragment CareerFragment on Nmbl_Career {
    alias {
      alias
    }
    displayText
    location
    htmlBody {
      ...HtmlBodyFragment
    }
    drawerItems {
      contentItems {
        ... on Nmbl_EditorWidget {
          htmlBody {
            ...HtmlBodyFragment
          }
        }
      }
    }
    bag {
      contentItems {
        ... on Nmbl_FormField {
          fieldLabel
          required
        }
      }
    }
  }
`;

export const Career: React.FC<CareerFragmentFragment> = props => (
  <StyledCareer className="career">
    <StyledInViewElement>
      <Heading3 className="career__position">{props.displayText}</Heading3>
      <StyledParagraph className="career__location">
        Philadelphia, PA
      </StyledParagraph>
      <CmsContent className="career__description" html={props.htmlBody?.html} />
      <Drawer>
        <div className="career__drawer-items">
          {props.drawerItems?.contentItems
            ?.filter(filterNotEmpty)
            .map((editorWidget, index) => (
              <StyledCmsContent
                key={index}
                className="editor"
                html={editorWidget.htmlBody?.html}
              />
            ))}
        </div>
        <StyledLink to={`#apply/${props.alias?.alias}`}>
          Apply to Position
        </StyledLink>
      </Drawer>
    </StyledInViewElement>
  </StyledCareer>
);
