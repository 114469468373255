import { IModalParams } from './modal-container';

export function selectModalParamsFromHash(hash: Location['hash']) {
  let modalId: string | undefined;
  let modalParams: string[] = [];
  let modal: IModalParams | undefined;

  if (hash) {
    modalId = hash;

    if (modalId.substr(0, 1) === '#') {
      modalId = modalId.substr(1);
    }

    [modalId, ...modalParams] = modalId.split('/');

    modal = { modalId, modalParams };
  }

  return modal;
}
