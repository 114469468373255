import React from 'react';
import {
  Dimensions,
  useScrollState,
  useTransitioningElementsState,
} from './window-provider';

export type IWindowContext = {
  windowSize?: Dimensions;
} & Partial<ReturnType<typeof useScrollState>> &
  Partial<ReturnType<typeof useTransitioningElementsState>>;

export const WindowContext = React.createContext<IWindowContext | undefined>(
  undefined
);

export const useWindowContext = () => {
  const inViewContext = React.useContext(WindowContext);

  if (!inViewContext) {
    throw new Error('In View Context used outside of a InViewContainer');
  }

  return inViewContext;
};
