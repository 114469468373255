import { ModalSwitch } from './modal-switch';
import styled from 'styled-components';
import { modalTransition } from './modal-renderer.styles';

export const StyledModalSwitch = styled(ModalSwitch)`
  ${modalTransition}
  width: 100%;
  height: 100%;

  &.exit-active,
  &.exit-done {
    opacity: 0;
    pointer-events: none;
  }

  &.appear,
  &.appear-active,
  &.enter {
    opacity: 0;
  }

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &.enter-done {
    pointer-events: all;
  }
`;
