import React from 'react';
import classNames from 'classnames';
import { useSiteMetaContext } from '../../site-meta';
import { useModalContext } from '../../modal-container/modal-container.context';
import { StyledModalContent } from './modal-content.styles';
import { StyledWrapper } from '../../parts/wrapper.styles';
import { ThemeName } from '../../../helpers/theme-helpers';
import { useLocation } from '@reach/router';
import { modalViewEvent } from '../../../helpers/analyticsHelpers';

export interface IModalContentProps {
  theme: ThemeName;
}

export const ModalContent: React.FC<IModalContentProps> = props => {
  const siteMetaContext = useSiteMetaContext();
  const modalContext = useModalContext();
  const location = useLocation();

  React.useEffect(() => {
    siteMetaContext.setHeaderTheme(props.theme);
    siteMetaContext.setModalTheme(props.theme);

    modalViewEvent(location);

    return () => {
      if (!modalContext.modalRef.current) {
        siteMetaContext.setHeaderTheme();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledWrapper
      as={StyledModalContent}
      className={classNames('modal-content', `theme--${props.theme}`)}
    >
      {props.children}
    </StyledWrapper>
  );
};
