import React from 'react';
import { useSiteMetaContext } from '../site-meta';
import { ModalContent } from './parts/modal-content';
import { NavMenu } from '../navigation/nav-menu';

export const NavigationModal = () => {
  const siteMeta = useSiteMetaContext();

  return (
    <ModalContent theme="dark">
      <NavMenu menu={siteMeta.mainMenu} />
    </ModalContent>
  );
};
