import { Range } from './input-helpers';
export type CheckboxValueTypes = string | number;

export interface IResume {
  type: string;
}

export const validateText = (label: string, required = true) => (
  value: string
) => {
  if (required && !value) {
    return `Please enter your ${label}.`;
  }
};

export const REGEXP_EMAIL_FULL = /[^@]+@[A-Z0-9.-]+\.[A-Z]{2,}/i;

export const isValidEmail = (emailLike: string) => {
  let valid = false;

  if (emailLike || (emailLike as any) === 0) {
    const matches = emailLike.match(REGEXP_EMAIL_FULL);

    if (matches) {
      valid = matches.length > 0;
    }
  }

  return valid;
};

export const validateEmail = (required = true) => (value: string) => {
  if (required && !value) {
    return 'Please enter your email.';
  } else if (value && !isValidEmail(value)) {
    return 'Please enter a valid email.';
  }
};

export const REGEXP_PHONE_PARTIAL = /(1)?(\d{0,3})(\d{0,3})(\d{0,4})/;
export const REGEXP_PHONE_PARTIAL_RAW = /(1)?\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/;
export const REGEXP_PHONE_FULL = /(1)?([2-9]\d{9})/;

export const isValidRawPhoneNumber = (phoneLike: string) => {
  let valid = false;

  if (phoneLike || (phoneLike as any) === 0) {
    const matches = phoneLike.match(REGEXP_PHONE_FULL);

    if (matches) {
      valid = matches.length > 0;
    }
  }

  return valid;
};

export const validatePhone = (required = true) => (value: string) => {
  if (!value) {
    if (required) {
      return 'Please enter your phone number.';
    }
  } else if (!isValidRawPhoneNumber(value)) {
    return 'Please enter a valid phone number.';
  }
};

export const validResumeExtensions = ['.doc', '.docx', '.pdf'];

export const validResumeMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];

export const validResumeMimeTypesAndExtensions = [
  ...validResumeExtensions,
  ...validResumeMimeTypes,
];

export const isValidResume = (value: IResume | '') =>
  value && validResumeMimeTypes.includes(value.type);

export const validateResume = (required = true) => (value: IResume | '') => {
  if (required && !value) {
    return 'Please attach your resume.';
  } else if (value && !isValidResume(value)) {
    return 'Please upload one of the following file types: Word Document, PDF.';
  }
};

export type NumberFormatCallback = (value: number | undefined) => string;

export const validateRange = (
  min?: number,
  max?: number,
  required = true,
  formatter: NumberFormatCallback = value => `${value}`
) => (value?: Range) => {
  if (required && !value) {
    return 'Please select a range.';
  } else if (
    value &&
    (typeof value.min !== 'number' ||
      (typeof min === 'number' && value.min < min) ||
      typeof value.max !== 'number' ||
      (typeof max === 'number' && value.max > max))
  ) {
    return `Please select a range between ${formatter(min)} and ${formatter(
      max
    )}`;
  }
};

export const stringIsFalsy = (value?: unknown) =>
  !value || (typeof value === 'string' && (value === '' || value === '-1'));

export const numberIsFalsy = (value?: unknown) =>
  !value || (typeof value === 'number' && value === -1);

export const arrayIsFalsy = (value?: unknown) =>
  !value || (Array.isArray(value) && value.length === 0);

export const valueIsFalsy = (value?: unknown) =>
  stringIsFalsy(value) || numberIsFalsy(value) || arrayIsFalsy(value);

export const skipValidation = () => undefined;

export const validateCheckboxGroupSelection = (required = true) => <
  ValueType extends CheckboxValueTypes
>(
  values?: ValueType[]
) => {
  if (required && valueIsFalsy(values)) {
    return 'Please select an option.';
  }
};

export const handleCheckboxGroupValidation = <
  ValueType extends CheckboxValueTypes
>(
  required?: false
) => (values?: ValueType[]) => {
  if (required && valueIsFalsy(values)) {
    return 'Please select an option.';
  }
};
