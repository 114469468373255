import React from 'react';
import { IModalParams, ModalAnimationState } from './modal-container';
import { ThemeName } from '../../helpers/theme-helpers';

export interface IModalContext extends IModalParams {
  onClose: () => void;
  modal: React.ComponentType | undefined;
  modalRef: React.RefObject<React.ComponentType | undefined>;
  onAnimationStateChange: (animationState: ModalAnimationState) => void;
  animationState: ModalAnimationState;
  savedScrollPosition: number;
  updatePageHeaderTheme: (theme: ThemeName) => void;
}

export const ModalContext = React.createContext<IModalContext | undefined>(
  undefined
);

export const useModalContext = () => {
  const modalContext = React.useContext(ModalContext);

  if (!modalContext) {
    throw new Error('Modal Context used outside of a ModalContainer');
  }

  return modalContext;
};
