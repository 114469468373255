import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  CSSTransition,
  SwitchTransition,
  TransitionGroup,
} from 'react-transition-group';
import { SpecialMessageQuery } from '../../../types/gatsby-types';
import { baseTransitionTimingValue } from '../../abstracts/animation.styles';
import { useLocalStorage } from '../../helpers/hooks';
import { SpecialMessage } from './special-message';

export const useSpecialMessageQuery = () => {
  const specialMessageQuery = useStaticQuery<SpecialMessageQuery>(graphql`
    query SpecialMessage {
      nmbl {
        specialMessage {
          ...SpecialMessageFragment
        }
      }
    }
  `);

  return specialMessageQuery;
};

export const SpecialMessageWrapper: React.FC = () => {
  const query = useSpecialMessageQuery();
  const specialMessages = query.nmbl.specialMessage;
  const [
    closedSpecialMessages,
    setClosedSpecialMessages,
  ] = useLocalStorage('closedSpecialMessages', ['']);

  const closeSpecialMessage = (contentItemVersionId: string) => {
    setClosedSpecialMessages([...closedSpecialMessages, contentItemVersionId]);
  };

  const specialMessage = React.useMemo(() => {
    const today = new Date();
    const specialMessage =
      specialMessages &&
      specialMessages.find(message => {
        const startTime = message?.startTime && new Date(message.startTime);
        const endTime = message?.endTime && new Date(message.endTime);

        return (
          !!message?.contentItemVersionId &&
          !closedSpecialMessages.includes(message?.contentItemVersionId) &&
          (!startTime || startTime < today) &&
          (!endTime || endTime >= today)
        );
      });

    return (
      <CSSTransition
        timeout={baseTransitionTimingValue}
        key={specialMessage?.contentItemVersionId}
      >
        {specialMessage ? (
          <SpecialMessage {...specialMessage} onClose={closeSpecialMessage} />
        ) : (
          <></>
        )}
      </CSSTransition>
    );
  }, [closedSpecialMessages, specialMessages]);

  return (
    <TransitionGroup component={null}>
      <SwitchTransition>{specialMessage}</SwitchTransition>
    </TransitionGroup>
  );
};
