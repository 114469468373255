import React from 'react';
import { useModalContext } from '../modal-container/modal-container.context';
import { ModalContent } from './parts/modal-content';
import { useLastKnown } from '../../helpers/hooks';
import { useCareersQuery } from '../widgets/careers-widget';
import { Formik, Form } from 'formik';
import { TextInput } from '../form-inputs/text-input';
import { filterNotEmpty } from '../../helpers/arrayHelpers';
import { submitApplication } from '../../actions/submitApplication';
import { FileInput } from '../form-inputs/file-input';
import { FormWrapper } from '../forms/form-wrapper';
import { FormContent } from '../forms/form-content';
import { FormError } from '../forms/form-error';
import { FormSuccess } from '../forms/form-success';
import { SubmitButton } from '../form-inputs/submit';
import { EmailInput } from '../form-inputs/email-input';
import { validResumeMimeTypesAndExtensions } from '../../helpers/validation-helpers';
import { PhoneInput } from '../form-inputs/phone-input';
import { Heading1 } from '../../abstracts/typography.styles';
import { ApplyRequestApiModel } from '../../../api/api-models';

export const ApplyModal: React.FC = () => {
  const { modalParams } = useModalContext();
  const query = useCareersQuery();

  const career = useLastKnown(
    modalParams && modalParams[0]
      ? query.nmbl.career?.find(
          career => modalParams[0] === career?.alias?.alias
        )
      : undefined
  );

  if (!career) {
    throw new Error('Invalid Career');
  }

  const customFields = career.bag?.contentItems;

  const sanitizeFieldName = (fieldName: string) =>
    fieldName.replace(/[^a-z0-9 ]/gi, '').trim();

  const initialCustomFieldValues = React.useMemo(
    () =>
      customFields?.reduce<Record<string, string>>(
        (allFields, currentField) => {
          const fieldName = sanitizeFieldName(currentField?.fieldLabel ?? '');
          allFields[fieldName] = '';

          return allFields;
        },
        {}
      ),
    [customFields]
  );

  return (
    <ModalContent theme="light">
      <FormWrapper>
        {formContext => (
          <>
            <FormContent>
              <Heading1 as="h2">Apply for: {career.displayText}</Heading1>
              <Formik<ApplyRequestApiModel>
                initialValues={{
                  position: career.displayText ?? '',
                  name: '',
                  email: '',
                  phone: '',
                  website: '',
                  resume: '',
                  ...initialCustomFieldValues,
                }}
                onSubmit={async values => {
                  formContext.beforeSubmit();

                  try {
                    await submitApplication(values);

                    formContext.onSuccess();
                  } catch (err) {
                    console.log(err);

                    formContext.onError();
                  }
                }}
              >
                <Form noValidate>
                  <TextInput name="name" label="Name" />
                  <EmailInput name="email" label="Email" />
                  <PhoneInput name="phone" label="Phone" />
                  <TextInput
                    name="website"
                    label="Your Website"
                    required={false}
                  />
                  {customFields?.filter(filterNotEmpty).map(customField => (
                    <TextInput
                      key={customField.fieldLabel ?? ''}
                      type="textarea"
                      name={sanitizeFieldName(customField.fieldLabel ?? '')}
                      label={customField.fieldLabel ?? ''}
                      required={!!customField?.required}
                    />
                  ))}
                  <FileInput
                    name="resume"
                    label="Resume"
                    accept={validResumeMimeTypesAndExtensions.join(',')}
                  />
                  <SubmitButton />
                </Form>
              </Formik>
            </FormContent>
            <FormSuccess>
              <h1>Thanks for your application.</h1>
            </FormSuccess>
            <FormError />
          </>
        )}
      </FormWrapper>
    </ModalContent>
  );
};
