import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid12 } from '../../abstracts/theme.styles';
import {
  themedMarginTop,
  themedMarginBottom,
} from '../../helpers/theme-helpers';

export const StyledCareersWidget = styled.div`
  ${themedMarginTop('80px')}
  ${themedMarginBottom('60px')}

  @media ${media.tablet} {
    ${grid12}
  }

  @media ${media.tabletLandscape} {
    ${themedMarginTop('151px')}
    ${themedMarginBottom('120px')}
  }

  .careers-widget__spacer {
    @media ${media.tabletLandscape} {
      grid-column: auto / span 1;
    }
  }

  .career {
    margin-top: 90px;

    &:first-of-type {
      margin-top: 0;
    }

    @media ${media.tablet} {
      grid-column: auto / span 7;
    }

    @media ${media.tabletLandscape} {
      grid-column: auto / span 4;
      margin-top: 0;
    }

    @media ${media.desktop} {
      grid-column: auto / span 4;
    }

    @media ${media.ieTabletLandscape} {
      margin-bottom: 100px;
    }

    &__location {
      margin-top: -4px;
    }
  }
`;
