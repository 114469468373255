import { throwIfBadHttpResponse } from '../helpers/formHelpers';
import {
  InquiryRequestApiModel,
  InquiryResponseApiModel,
} from '../../api/api-models';

export const submitInquiry = async (
  request: InquiryRequestApiModel
): Promise<InquiryResponseApiModel> => {
  const res = await fetch('/api/inquiry', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });
  throwIfBadHttpResponse(res);

  return await res.json();
};
