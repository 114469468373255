import React from 'react';
import { Header } from '../parts/header';
import { Footer } from '../parts/footer';
import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetaContext, useSiteMetaContext } from '../site-meta';
import { LayoutQuery } from '../../../types/gatsby-types';
import { ModalRenderer } from '../modals/parts/modal-renderer';
import { ModalContainer } from '../modal-container/modal-container';
import { modals } from '../modals';
import { LinkProvider } from '../navigation/link-provider';
import { WindowContextProvider } from '../in-view/window-provider';
import { LayoutInner } from './layout.styles';
import { GlobalStyle } from '../../base/base.styles';
import { ThemeProvider } from 'styled-components';
import { PageTransition } from '../page-transition/page-transition';
import { PageTransitionContextProvider } from '../page-transition/page-transition-provider';
import { IWorkListTemplateProps } from '../templates/work-list';
import { PageTemplateProps } from '../templates/kitchen-sink';
import { WorkTemplateProps } from '../templates/work';
import { Load } from '../page-load/load';
import { is404Page, selectCanonicalBaseUrl } from '../../helpers/site-helpers';
import {
  isStringTheme,
  selectTheme,
  ThemeName,
} from '../../helpers/theme-helpers';
import { useModalContext } from '../modal-container/modal-container.context';
import { SpecialMessageWrapper } from '../parts/special-message-wrapper';

export interface ILayoutContext {
  /**
   * Set the underlying page header theme, while
   * preserving the current header theme used by modals
   */
  setPageHeaderTheme: (theme: ThemeName) => void;
  setHeaderTheme: (theme?: ThemeName, forPage?: boolean) => void;
  setModalTheme: (theme: string) => void;
  setFooterTheme: (theme: string) => void;
}

export const useLayoutTheme = (headerTheme: ThemeName, footerTheme: string) => {
  const { setFooterTheme } = useSiteMetaContext();
  const { updatePageHeaderTheme } = useModalContext();

  /**
   * Change themes on mount
   */
  React.useEffect(() => {
    updatePageHeaderTheme(headerTheme);
    setFooterTheme(footerTheme);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const Layout: React.FC<
  WorkTemplateProps | IWorkListTemplateProps | PageTemplateProps
> = props => {
  const query = useStaticQuery<LayoutQuery>(graphql`
    query Layout {
      metaJson {
        ...SiteMetaFragment
      }
      nmbl {
        menu {
          ...MenuFragment
        }
      }
    }
  `);
  if (!query.metaJson) {
    throw new Error('Could not load site metadata.');
  }
  const originalHeaderTheme =
    (isStringTheme(props.pageContext.headerTheme?.theme) &&
      props.pageContext.headerTheme?.theme) ||
    'dark';

  // header theme can temporarily switch for modals
  const pageHeaderTheme = React.useRef(originalHeaderTheme);
  const [currentHeaderTheme, setCurrentHeaderTheme] = React.useState(
    pageHeaderTheme.current
  );
  const [footerTheme, setFooterTheme] = React.useState(
    props.pageContext.footerTheme?.theme ?? 'dark'
  );
  const [modalTheme, setModalTheme] = React.useState('dark');

  const setPageHeaderTheme = React.useCallback((theme: ThemeName) => {
    pageHeaderTheme.current = theme;
  }, []);
  const setHeaderTheme = React.useCallback(
    (theme?: ThemeName, forPage = false) => {
      if (forPage && theme) {
        setPageHeaderTheme(theme);
      }
      setCurrentHeaderTheme(theme || pageHeaderTheme.current);
    },
    [pageHeaderTheme, setPageHeaderTheme]
  );

  const headerMenu = query.nmbl.menu?.find(
    menu => menu?.alias?.alias === query.metaJson?.pageSettings?.mainMenuSlug
  );

  const footerMenu = query.nmbl.menu?.find(
    menu => menu?.alias?.alias === query.metaJson?.pageSettings?.footerMenuSlug
  );

  if (!headerMenu || !footerMenu) {
    throw new Error('Could not get header and footer menu.');
  }

  return (
    <SiteMetaContext.Provider
      value={{
        ...query.metaJson,
        setPageHeaderTheme,
        setHeaderTheme,
        setModalTheme,
        setFooterTheme,
        mainMenu: headerMenu,
      }}
    >
      <WindowContextProvider>
        <GlobalStyle />
        <LinkProvider internalPaths={[selectCanonicalBaseUrl()]}>
          <PageTransitionContextProvider>
            <ModalContainer modals={modals}>
              <div className="layout">
                <ThemeProvider theme={selectTheme(currentHeaderTheme)}>
                  <Load is404Page={is404Page(props.pageContext.pagePath)} />
                </ThemeProvider>
                <ThemeProvider theme={selectTheme(modalTheme)}>
                  <ModalRenderer />
                </ThemeProvider>
                <ThemeProvider theme={selectTheme(currentHeaderTheme)}>
                  <Header
                    menu={headerMenu}
                    headerTheme={
                      props.pageContext.contentType === 'Work'
                        ? originalHeaderTheme
                        : undefined
                    }
                    smartHeaderTheme={
                      props.pageContext.contentType === 'Work'
                        ? 'light'
                        : undefined
                    }
                  />
                </ThemeProvider>
                <PageTransition>
                  <LayoutInner>{props.children}</LayoutInner>
                </PageTransition>
                <ThemeProvider theme={selectTheme(footerTheme)}>
                  <Footer menu={footerMenu} />
                </ThemeProvider>
                <ThemeProvider theme={selectTheme('dark')}>
                  <SpecialMessageWrapper />
                </ThemeProvider>
              </div>
            </ModalContainer>
          </PageTransitionContextProvider>
        </LinkProvider>
      </WindowContextProvider>
    </SiteMetaContext.Provider>
  );
};

export default Layout;
