import React from 'react';
import classNames from 'classnames';
import { useInViewContext } from './in-view-container.context';
import {
  leftPosition,
  topPosition,
  height,
} from '../../helpers/positionHelpers';
import { useWindowContext } from './window-provider.context';

export interface IInViewElementProps {
  className?: string;
}

export const InViewElement: React.FC<IInViewElementProps> = props => {
  const { windowSize } = useWindowContext();
  const { registerElement, updateElement } = useInViewContext();
  const inViewElementRef = React.useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = React.useState(false);
  const [isTransitioning, setIsTransitioning] = React.useState(false);
  const [isTransitionComplete, setIsTransitionComplete] = React.useState(false);

  const getElementProps = () => {
    return {
      yPos:
        (inViewElementRef.current && topPosition(inViewElementRef.current)) ??
        undefined,
      xPos:
        (inViewElementRef.current && leftPosition(inViewElementRef.current)) ??
        undefined,
      height:
        (inViewElementRef.current && height(inViewElementRef.current)) ??
        undefined,
      inViewCallback: () => {
        console.debug('InViewElement: InViewCallback');
        setIsInView(true);
      },
      animationCallback: () => {
        console.debug('InViewElement: AnimationCallback');
        setIsTransitioning(true);
      },
      isDoneCallback: () => {
        console.debug('InViewElement: IsDoneCallback');
        setIsTransitionComplete(true);
      },
      ref: inViewElementRef,
    };
  };

  React.useEffect(() => {
    registerElement({ ...getElementProps(), isVisible: false });

    return () => {
      // If we want to un-register elements do it here
    };
  }, [registerElement]);

  React.useEffect(() => {
    // Update dimensions on resize
    if (!isInView) {
      updateElement({ ...getElementProps(), isVisible: false });
    }
  }, [isInView, updateElement, windowSize]);

  return (
    <div
      className={classNames(
        'in-view-element',
        (isTransitioning || isTransitionComplete) && `in-view-element--visible`,
        props.className && props.className
      )}
      ref={inViewElementRef}
    >
      {props.children}
    </div>
  );
};
