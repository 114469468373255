import React from 'react';
import { TextInput, TextInputProps } from './text-input';
import { validateEmail } from '../../helpers/validation-helpers';

export type EmailInputProps = Omit<TextInputProps, 'type'>;

export const EmailInput: React.FC<EmailInputProps> = props => {
  const validate = React.useCallback(validateEmail(props.required), [
    props.required,
  ]);
  return (
    <TextInput validate={props.validate ?? validate} type="email" {...props} />
  );
};
