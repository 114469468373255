import styled from 'styled-components';
import { Link } from './link';
import { font } from '../../abstracts/variables/fonts.styles';

export const StyledLink = styled(Link)`
  font-family: ${font.primary};
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.secondary};
  color: ${props => props.theme.secondary};
  transition: border-bottom 0.2s linear, color 0.2s linear;

  &:hover {
    border-bottom: 1px solid ${props => props.theme.secondaryOpaque};
  }

  &:visited {
    color: ${props => props.theme.secondary};
  }
`;
