export const color = {
  /* base colors 🎨 */
  basalt: '#111114',
  black: '#111114',
  white: '#ffffff',
  silver: '#e7e7e7',
  ash: '#b8b8b8',

  /* secondary colors 🎨 */
  carmine: '#f25144',
  kelp: '#095b5b' /* 🧜 */,
  cobalt: '#2d338e',
  sulfur: '#fcd116',
  stone: '#58585a',

  /* tertiary colors 🎨 */
  lightCarmine: '#f7ebe2',
  lightKelp: '#d5f2f2',
  lightCobalt: '#c1c9dd',
  lightSulfur: '#fcf6e5',
};
