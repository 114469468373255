import React from 'react';
import classNames from 'classnames';

export interface IModalSwitchProps {
  className?: string;
  modalId?: string;
}

export const ModalSwitch: React.FC<IModalSwitchProps> = props => (
  <div
    className={classNames(
      props.className,
      `${props.className}--${props.modalId}`
    )}
  >
    {props.children}
  </div>
);
