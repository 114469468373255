import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import {
  baseEasingFunction,
  transitionTiming,
} from '../../abstracts/animation.styles';
import { layers } from '../../abstracts/theme.styles';
import { grid } from '../../abstracts/theme.styles';

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  background-color: transparent !important;
  transform: translate3d(0, -120px, 0);
  transition: transform ${transitionTiming('base')} ${baseEasingFunction};
  z-index: ${layers.header};

  @media ${media.tabletLandscape} {
    padding-right: ${grid.offset.tabletLandscape};
    padding-left: ${grid.offset.tabletLandscape};
  }

  @media ${media.desktop} {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: row;
    padding-right: ${grid.offset.desktop};
    padding-left: ${grid.offset.desktop};
  }

  @media ${media.desktopLarge} {
    padding-right: ${grid.offset.desktopLarge};
    padding-left: ${grid.offset.desktopLarge};
  }

  &.header--visible {
    transform: translate3d(0, 0, 0);

    .header__navigation::before {
      transition: background-color ${transitionTiming('base')}
          ${baseEasingFunction},
        opacity ${transitionTiming('base')} ${baseEasingFunction};
    }

    .modal-open & {
      pointer-events: none;

      .header__logo,
      .header__menu {
        pointer-events: all;
      }
    }

    &.header--modal-animation-exit,
    &.header--modal-animation-exiting {
      .page-transitioning & {
        .header__navigation {
          &::before,
          &::after {
            opacity: 0;
            transition-delay: 0s;
          }
        }
      }
    }

    .header__logo,
    .header__menu {
      transition: fill ${transitionTiming('base')} ${baseEasingFunction},
        opacity ${transitionTiming('base')} ${baseEasingFunction} 0s;
    }
  }

  &.header--at-top {
    .header__navigation::before {
      opacity: 0;
      background-color: transparent !important;
      transition: background-color 0ms ${baseEasingFunction},
        opacity ${transitionTiming('base')} ${baseEasingFunction};
    }

    .header__navigation::after {
      opacity: 0;
    }
  }

  .page-transitioning & {
    transition: transform ${transitionTiming('base')} ${baseEasingFunction}
      ${transitionTiming('base')};
  }

  &.header--modal-animation-enter {
    .header__navigation {
      &::before {
        transition: background-color 0ms ${baseEasingFunction};
      }

      &::after {
        transition: opacity 0ms ${baseEasingFunction};
      }
    }

    &.header--visible {
      .header__navigation {
        &::before {
          transition-delay: ${transitionTiming('base')};
        }

        &::after {
          transition: opacity ${transitionTiming('base')} ${baseEasingFunction};
        }
      }
    }

    .header__logo,
    .header__menu {
      transition-delay: ${transitionTiming('base', 1.2)};

      &:hover {
        transition-delay: 0s;
      }
    }
  }

  &.header--modal-animation-entering {
    &.header--visible {
      .header__navigation {
        &::after {
          opacity: 0;
        }
      }
    }

    &:not(.header--at-top) {
      .header__navigation {
        &::before {
          transition: background-color ${transitionTiming('base')}
            ${baseEasingFunction};
          transition-delay: ${transitionTiming('base')};
        }

        &::after {
          opacity: 1;
          transition: opacity ${transitionTiming('base')} ${baseEasingFunction};
        }
      }
    }
  }

  &.header--modal-animation-entered {
    .header__navigation {
      &::before,
      &::after {
        opacity: 0;
        transition: opacity 0ms ${baseEasingFunction};
      }
    }

    .header__menu {
      transition-delay: 0s;

      &:hover {
        transition-delay: 0s;
      }
    }
  }

  &.header--modal-animation-entered,
  &.header--modal-animation-exit,
  &.header--modal-animation-exiting {
    .header__navigation {
      &::before {
        transition: opacity ${transitionTiming('base')} ${baseEasingFunction};
        transition-delay: ${transitionTiming('base')};
      }
    }

    .header__logo,
    .header__menu {
      transition-delay: ${transitionTiming('base')};

      &:hover {
        transition-delay: 0s;
      }
    }

    .header__menu {
      transition-delay: 0s;
    }
  }

  &.header--smart-header-was-active {
    .header__navigation {
      &::before {
        transition: opacity ${transitionTiming('base')} ${baseEasingFunction},
          background-color ${transitionTiming('base')} ${baseEasingFunction};
        opacity: 0;
      }
    }
  }

  .header__navigation {
    display: flex;

    /* Base background */
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${props => props.theme.primary};
      opacity: 1;
      transition: background-color ${transitionTiming('base')}
          ${baseEasingFunction},
        opacity 0ms ${baseEasingFunction} ${transitionTiming('base')};
      z-index: -20;
      pointer-events: none;

      .page-transitioning & {
        transition: background-color ${transitionTiming('base')}
            ${baseEasingFunction} ${transitionTiming('base', 2)},
          opacity ${transitionTiming('base')} ${baseEasingFunction};
      }
    }

    /* Modal transition background */
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${props => props.theme.primary};
      opacity: 0;
      transition: background-color 0ms ${baseEasingFunction},
        opacity 0ms ${baseEasingFunction};
      z-index: -10;
      pointer-events: none;
    }
  }

  .header__logo {
    display: inline-block;
    width: 56px;
    height: 16px;
    fill: ${props => props.theme.secondary};

    @media ${media.desktop} {
      width: 70px;
      height: 20px;
    }
  }

  .header__menu {
    margin-left: auto;
    fill: ${props => props.theme.secondary};
    width: 20px;
    height: 20px;
    display: inline-block;
    opacity: 1;

    &:hover {
      opacity: 0.3;
    }

    clipPath {
      transform: rotate(45deg) translate(0, 0);
      transform-origin: center;
    }

    .menu-clip__shape {
      transition: transform 0.2s ${baseEasingFunction};
      transform: scale(1.15);

      &--one {
        transform-origin: top right;
      }

      &--two {
        transform-origin: bottom left;
      }

      .modal-open & {
        transform: scale(1);
      }
    }
  }

  .menu__bar {
    transition: transform 0.2s ${baseEasingFunction},
      fill 0.2s ${baseEasingFunction};

    &--top {
      fill: ${props => props.theme.secondary};
      transition: transform 0.2s ${baseEasingFunction},
        fill 0.2s ${baseEasingFunction};
      clip-path: url(#menu-clip);
    }

    &--bottom {
      fill: ${props => props.theme.secondary};
      transform-origin: right top;
    }
  }

  .modal-open & {
    .menu__bar {
      transition: transform 0.2s ${baseEasingFunction},
        fill 0.2s ${baseEasingFunction} 0.2s;
    }

    .menu__bar--top {
      transform: rotate(45deg) translate(4px, -7px);
    }

    .menu__bar--bottom {
      transform: rotate(-45deg) translate(17px, -14px) scaleX(2);
      fill: ${props => props.theme.secondary};
    }
  }
`;
