import styled from 'styled-components';
import { media } from '../../abstracts/media-settings.styles';
import { grid } from '../../abstracts/theme.styles';
import { IStyledWrapperProps } from './wrapper';

export const StyledWrapper = styled.div<IStyledWrapperProps>`
  padding-right: ${props => props.mobilePadding || grid.offset.mobile};
  padding-left: ${props => props.mobilePadding || grid.offset.mobile};
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};

  @media ${media.tabletLandscape} {
    padding-right: ${grid.offset.tabletLandscape};
    padding-left: ${grid.offset.tabletLandscape};
  }

  @media ${media.desktop} {
    padding-right: ${grid.offset.desktop};
    padding-left: ${grid.offset.desktop};
  }

  @media ${media.desktopLarge} {
    padding-right: ${grid.offset.desktopLarge};
    padding-left: ${grid.offset.desktopLarge};
  }
`;
