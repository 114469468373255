// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-kitchen-sink-tsx": () => import("./../src/components/templates/kitchen-sink.tsx" /* webpackChunkName: "component---src-components-templates-kitchen-sink-tsx" */),
  "component---src-components-templates-page-tsx": () => import("./../src/components/templates/page.tsx" /* webpackChunkName: "component---src-components-templates-page-tsx" */),
  "component---src-components-templates-work-list-tsx": () => import("./../src/components/templates/work-list.tsx" /* webpackChunkName: "component---src-components-templates-work-list-tsx" */),
  "component---src-components-templates-work-tsx": () => import("./../src/components/templates/work.tsx" /* webpackChunkName: "component---src-components-templates-work-tsx" */)
}

