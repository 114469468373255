import React from 'react';
import classNames from 'classnames';
import { StyledCmsContent } from './cms-content.styles';

export interface ICmsContentProps {
  className?: string;
  html: string | undefined;
}

export const CmsContent: React.FC<ICmsContentProps> = props => (
  <StyledCmsContent
    className={classNames('cms-content', props.className)}
    dangerouslySetInnerHTML={{ __html: props.html ?? '' }}
  />
);
