import { themes } from '../abstracts/theme.styles';

export type ThemeName = 'light' | 'dark';

export const isStringTheme = (
  theme: string | undefined
): theme is keyof typeof themes => !!theme && !!(themes as any)[theme];

export const getThemeOrDefault = (
  theme: string | undefined,
  fallback: ThemeName = 'light'
) => (isStringTheme(theme) && theme) || fallback;

export const selectTheme = (theme: string | undefined) => {
  if (isStringTheme(theme)) {
    return themes[theme];
  }
  // should we default to light or dark?
  return themes.light;
};

export const themedMarginTop = (
  marginTop: string | number,
  paddingTop = marginTop
) => `
    margin-top: ${marginTop};

    .theme-transition-before & {
      margin-top: 0;
      padding-top: ${paddingTop};
    }
  `;

export const themedMarginBottom = (
  marginBottom: string | number,
  paddingBottom = marginBottom
) => `
      margin-bottom: ${marginBottom};

      .theme-transition-after & {
        margin-bottom: 0;
        padding-bottom: ${paddingBottom};
      }
    `;
