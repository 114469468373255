import styled from 'styled-components';

export const StyledCareer = styled.div`
  color: ${props => props.theme.secondary};

  .career__position {
    margin-top: 0;
    margin-bottom: 0;
  }

  .career__location {
    margin-top: 0;
  }
`;
