import { css, keyframes } from 'styled-components';
import { color } from './variables/colors.styles';

export type transitionTypes = 'base' | 'hover' | 'loader' | 'imageScale';
export const baseTransitionTimingValue = 350;
export const hoverTransitionTimingValue = 200;
export const imageScaleTransitionTimingValue = 400;
export const loaderTransitionTimingValue = 2000;

export const baseEasingFunction = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';
export const imageScaleEasingFunction = 'cubic-bezier(0.87, 0, 0.13, 1)';
export const imageScaleOutEasingFunction = 'cubic-bezier(0.25, 1, 0.5, 1)';

const fadeOpacityIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const transitionTiming = (
  type: transitionTypes,
  multiplier: number = 1
) => {
  let timingValue = baseTransitionTimingValue;

  switch (type) {
    case 'hover':
      timingValue = hoverTransitionTimingValue;
      break;
    case 'loader':
      timingValue = loaderTransitionTimingValue;
      break;
    case 'imageScale':
      timingValue = imageScaleTransitionTimingValue;
      break;
    default:
      timingValue = baseTransitionTimingValue;
      break;
  }

  return timingValue * multiplier + 'ms';
};

export const animation = {
  hover: `${transitionTiming('hover')} ${baseEasingFunction}`,
  imageHover: `${transitionTiming('imageScale')} ${imageScaleEasingFunction}`,
  imageHoverOut: `${transitionTiming(
    'imageScale'
  )} ${imageScaleOutEasingFunction}`,
  specialMessage: `${transitionTiming('base')} ${baseEasingFunction}`,
  modal: `${transitionTiming('base')} ${baseEasingFunction}`,
};

export const headerModalOpenAnimation = css`
  animation: ${fadeOpacityIn} ${transitionTiming('base')} ${baseEasingFunction};
`;

export const colourShiftBackgroundLoad = keyframes`
  0% {
    background-color: ${color.black};
  }

  10% {
    background-color: ${color.black};
  }

  45% {
    background-color: ${color.carmine};
  }

  80% {
    background-color: ${color.cobalt};
    opacity: 1;
  }

  100% {
    background-color: ${color.cobalt};
    opacity: 0;
  }
`;

export const dotRotateOne = keyframes`
  0% {
    opacity: 1;
    transform: rotate(0deg) scale(0.5);
  }

  10% {
    opacity: 1;
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    opacity: 0;
    transform: rotate(15deg) scale(1.5);
  }
`;

export const dotRotateTwo = keyframes`
  0% {
    opacity: 1;
    transform: rotate(0deg) scale(0.51);
  }

  10% {
    opacity: 1;
    transform: rotate(0deg) scale(0.51);
  }

  100% {
    opacity: 0;
    transform: rotate(55deg) scale(1.51);
  }
`;
