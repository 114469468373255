import React from 'react';
import { StyledDrawer } from './drawer.styles';
import PlusSvg from '../../svg/plusSvg.svg';
import classNames from 'classnames';

export const Drawer: React.FC = props => {
  const [drawerIsOpen, setIsOpen] = React.useState<boolean>(false);
  const toggleDrawer = () => setIsOpen(!drawerIsOpen);

  return (
    <StyledDrawer
      className={classNames(
        'drawer',
        drawerIsOpen ? 'drawer--open' : 'drawer--closed'
      )}
    >
      <div className="drawer__content">{props.children}</div>
      <button className="drawer__toggle" onClick={toggleDrawer}>
        {drawerIsOpen ? 'Less' : 'More'}
        <span className="drawer__icon">
          <PlusSvg />
        </span>
      </button>
    </StyledDrawer>
  );
};
