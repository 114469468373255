import { css } from 'styled-components';
import { color } from './variables/colors.styles';
import { rgba } from 'polished';

// Theme settings
export const grid = {
  offset: {
    mobile: '30px',
    mobileLandscape: '30px',
    tablet: '30px',
    tabletLandscape: '30px',
    desktop: '60px',
    desktopLarge: 'calc(60 / 1440 * 100%)',
  },
  rawOffset: {
    mobile: '30',
    mobileLandscape: '30',
    tablet: '30',
    tabletLandscape: '30',
    desktop: '60',
  },
};

export const grid6 = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 60px;
`;

export const grid8 = css`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 60px;
`;

export const grid12 = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 25px;
`;

export const layers = {
  specialMessage: 70,
  modal: 80,
  header: 100,
  pageLoad: 400,
};

export const baseTheme = {};

export const lightTheme = {
  __name: 'light', // to making debugging easier
  primary: `${color.white}`,
  secondary: `${color.black}`,
  secondaryOpaque: `${rgba(color.black, 0.3)}`,
  secondaryWhisper: `${rgba(color.black, 0.1)}`,
  error: `${color.carmine}`,
};

export const darkTheme = {
  __name: 'dark', // to making debugging easier
  primary: `${color.black}`,
  secondary: `${color.white}`,
  secondaryOpaque: `${rgba(color.white, 0.3)}`,
  secondaryWhisper: `${rgba(color.white, 0.1)}`,
  error: `${color.carmine}`,
};

export const themes = {
  dark: darkTheme,
  light: lightTheme,
};
