import * as React from 'react';
import { FormWrapperContext, FormStatus } from './form-wrapper.context';
import { CSSTransition } from 'react-transition-group';
import { StyledFormWrapper, StyledFormContent } from './form-wrapper.styles';
import { StyledParagraph } from '../../abstracts/typography.styles';

export const FormError: React.FC = props => {
  const formContext = React.useContext(FormWrapperContext);

  return formContext?.status === FormStatus.Error ? (
    <CSSTransition timeout={350} key={FormStatus.Error}>
      <StyledFormWrapper className="form-error">
        {props.children || (
          <StyledFormContent as={StyledParagraph}>
            Sorry, something seems to have gone wrong. Please give us a call if
            this is an urgent message. Thanks!
          </StyledFormContent>
        )}
      </StyledFormWrapper>
    </CSSTransition>
  ) : null;
};
