import * as React from 'react';
import { FormWrapperContext, FormStatus } from './form-wrapper.context';
import { CSSTransition } from 'react-transition-group';
import {
  StyledFormWrapper,
  StyledForm,
  StyledFormContent,
} from './form-wrapper.styles';

export const FormContent: React.FC = props => {
  const formContext = React.useContext(FormWrapperContext);

  return formContext?.status === FormStatus.Filling ? (
    <CSSTransition timeout={350} key={FormStatus.Filling}>
      <StyledFormWrapper>
        <StyledFormContent as={StyledForm} className="form-content">
          {props.children}
        </StyledFormContent>
      </StyledFormWrapper>
    </CSSTransition>
  ) : null;
};
