import styled from 'styled-components';
import { media } from '../../../abstracts/media-settings.styles';
import { layers } from '../../../abstracts/theme.styles';
import {
  baseEasingFunction,
  transitionTiming,
} from '../../../abstracts/animation.styles';

export const modalTransition = `
  transition: opacity ${transitionTiming('base')} ${baseEasingFunction};
`;

// Everything below can stay here
export const StyledModalWrapper = styled.div`
  ${modalTransition}
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: ${layers.modal};
  pointer-events: none;

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &.enter-done {
    pointer-events: all;
  }
`;

export const StyledModal = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  width: 100vw;
  max-width: 100%;
  z-index: 10;
  overflow-y: auto;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};
  display: flex;
  flex-direction: column;

  &.modal--nav {
    justify-content: center;
    align-items: center;

    @media ${media.tabletLandscape} {
      justify-content: right;
      align-items: flex-end;
    }
  }
`;
