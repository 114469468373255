import * as React from 'react';
import { selectModalParamsFromHash } from './modal-container.helpers';
import { useLocation } from '@reach/router';
import { ModalContext } from './modal-container.context';
import { navigate } from 'gatsby';
import { scrollTop } from '../../helpers/positionHelpers';
import { useEventCallback, useLastRef } from '../../helpers/hooks';
import { useSiteMetaContext } from '../site-meta';
import { ThemeName } from '../../helpers/theme-helpers';

export type ModalComponents = Record<string, React.ComponentType>;

export type ModalAnimationState =
  | 'enter'
  | 'entering'
  | 'entered'
  | 'exit'
  | 'exiting'
  | 'exited';

export interface IModalContainerProps {
  bodyClass?: string;
  modals: ModalComponents;
}

export interface IModalParams {
  modalId?: string;
  modalParams?: string[];
}

export const ModalContainer: React.FC<IModalContainerProps> = props => {
  const $html = React.useRef<HTMLElement | null>(null);
  const modalRef = React.useRef<React.ComponentType | undefined>(undefined);
  const [openedFromHash, setOpenedFromHash] = React.useState(false);
  const [lastScrollPosition, setLastScrollPosition] = React.useState(0);
  const [activeModal, setActiveModal] = React.useState<
    IModalParams | undefined
  >();
  const { setHeaderTheme, setPageHeaderTheme } = useSiteMetaContext();
  const [animationState, setAnimationState] = React.useState<
    ModalAnimationState
  >('exited');
  const lastModalRef = useLastRef(activeModal);
  const location = useLocation();
  const bodyClass = props.bodyClass || 'modal-open';

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      $html.current = document.documentElement;
    }
  }, []);

  const handleOpen = useEventCallback(
    (modalParams: IModalParams, openedFromHash = false) => {
      const scrollTopValue = scrollTop();
      setLastScrollPosition(scrollTopValue);

      if ($html.current) {
        $html.current.style.top = `-${scrollTopValue}px`;
        $html.current.classList.add(bodyClass);
      }
      typeof window !== 'undefined' && window.scrollTo(0, 0);

      if (openedFromHash) {
        setOpenedFromHash(true);
      }

      setActiveModal(modalParams);
    },
    [bodyClass]
  );

  const handleRedirect = useEventCallback(modalParams => {
    typeof window !== 'undefined' && window.scrollTo(0, 0);
    setActiveModal(modalParams);
  }, []);

  const handleClose = useEventCallback(() => {
    if ($html.current) {
      $html.current?.classList.remove(bodyClass);
      $html.current.style.top = '';
    }
    typeof window !== 'undefined' && window.scrollTo(0, lastScrollPosition);

    setActiveModal(undefined);
    setOpenedFromHash(false);
  }, [bodyClass, lastScrollPosition]);

  // location.search based modal
  React.useEffect(() => {
    const modalParams = selectModalParamsFromHash(location.hash);
    if (modalParams && !lastModalRef.current) {
      handleOpen(modalParams, true);
    } else if (modalParams) {
      handleRedirect(modalParams);
    } else if (lastModalRef.current) {
      handleClose();
    }
  }, [location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  modalRef.current = React.useMemo(
    () =>
      activeModal?.modalId ? props.modals[activeModal.modalId] : undefined,
    [activeModal, props.modals]
  );

  const onAnimationStateChange = React.useCallback(
    (animationState: ModalAnimationState) => {
      setAnimationState(animationState);
    },
    [setAnimationState]
  );

  const updatePageHeaderTheme = React.useCallback(
    (theme: ThemeName) => {
      // set in background if modal is active
      if (modalRef.current) {
        setPageHeaderTheme(theme);
      } else {
        setHeaderTheme(theme, true);
      }
    },
    [setPageHeaderTheme, setHeaderTheme]
  );

  const onClose = React.useCallback(() => {
    setHeaderTheme();
    // remove hash if set from hash
    // it will then handleClose on hash change
    if (openedFromHash) {
      navigate(`${location.pathname}${location.search}`);
    } else {
      handleClose();
    }
  }, [
    setHeaderTheme,
    openedFromHash,
    location.pathname,
    location.search,
    handleClose,
  ]);

  return (
    <ModalContext.Provider
      value={{
        ...activeModal,
        onClose,
        updatePageHeaderTheme,
        modal: modalRef.current,
        modalRef: modalRef,
        onAnimationStateChange,
        animationState: animationState,
        savedScrollPosition: lastScrollPosition,
      }}
    >
      {props.children || (!!modalRef.current && <modalRef.current />)}
    </ModalContext.Provider>
  );
};
